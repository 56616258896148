import { useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { IPage } from 'src/lib/schemas';
import PagesList from './PagesList';
import { useLocation } from 'react-router-dom';
import AdAccounts from './AdAccounts';
import { isEmpty } from 'lodash';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { MdWarning } from 'react-icons/md';

interface SocialPagesProps {
	pages: IPage[];
	instagramPages?: IPage[];
	adAccounts?: any[];
	adAccountsLoading: boolean;
	redirectedFromFB: boolean;
}

const SocialPages = ({
	pages,
	instagramPages,
	adAccounts,
	adAccountsLoading,
	redirectedFromFB,
}: SocialPagesProps) => {
	const { state, pathname } = useLocation();
	const [selectedPages, setSelectedPages] = useState<IPage[]>([]);
	const [selectedIGPages, setSelectedIGPages] = useState<IPage[]>([]);
	const [selectedAdAccount, setSelectedAdAccount] = useState<any>();
	const [pagesError, setPagesError] = useState<string>();
	const [instagramPagesError, setInstagramPagesError] = useState<string>();

	const [adAccountError, setAdAccountError] = useState<string>();
	const {
		integrations,
		handleSaveSelectedPages,
		isSaving,
		getSelectedPagesIds,
		getSelectedIGAccountsIds,
		shouldLaunch,
		setShouldLaunch,
	} = useMyIntegrations();
	const accessTokens =
		state?.facebookTokens ?? integrations?.social?.fb?.attributes?.tokens;
	const saveAndPublish = pathname.includes('/projects/campaigns/');

	const pagesListRef = useRef<HTMLDivElement>(null);
	const adAccountsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!pages) return;
		const selectedIds = getSelectedPagesIds();
		if (selectedIds && selectedIds.length > 0) {
			const selectedPages: IPage[] = [];
			pages.forEach((page) => {
				selectedIds.includes(page.id) && selectedPages.push(page);
			});
			setSelectedPages(selectedPages);
		} else {
			setSelectedPages(pages);
		}

		if (integrations?.social?.fb?.attributes?.adAccounts) {
			setSelectedAdAccount(integrations?.social?.fb?.attributes?.adAccounts[0]);
		} else if (adAccounts && adAccounts.length > 0) {
			setSelectedAdAccount(adAccounts[0]);
		}

		if (instagramPages && instagramPages.length > 0) {
			const selectedIGIds = getSelectedIGAccountsIds();
			if (selectedIGIds && selectedIGIds.length > 0) {
				const selectedIGPages: IPage[] = [];
				instagramPages.forEach((page) => {
					selectedIGIds.includes(page.id) && selectedIGPages.push(page);
				});
				setSelectedIGPages(selectedIGPages);
			} else {
				setSelectedIGPages(instagramPages);
			}
		}

		if (integrations?.social?.fb?.attributes?.adAccounts) {
			setSelectedAdAccount(integrations?.social?.fb?.attributes?.adAccounts[0]);
		} else if (adAccounts && adAccounts.length > 0) {
			setSelectedAdAccount(adAccounts[0]);
		}
	}, [pages, integrations, adAccounts, instagramPages]);

	const handleSelectPage = (selectedPage: IPage) => {
		setSelectedPages((prevSelectedPages) =>
			prevSelectedPages.some((page) => page.id === selectedPage.id)
				? prevSelectedPages.filter((page) => page.id !== selectedPage.id)
				: [...prevSelectedPages, selectedPage],
		);
		setPagesError(undefined);
	};

	const handleSelectIGPage = (selectedPage: IPage) => {
		setSelectedIGPages((prevSelectedPages) =>
			prevSelectedPages.some((page) => page.id === selectedPage.id)
				? prevSelectedPages.filter((page) => page.id !== selectedPage.id)
				: [...prevSelectedPages, selectedPage],
		);
		setPagesError(undefined);
	};

	const handleSelectAdAccount = (account: any) => {
		setSelectedAdAccount(account);
		setAdAccountError(undefined);
	};

	const handleSubmit: any = async () => {
		let hasError = false;

		if (isEmpty(selectedPages)) {
			setPagesError('Please select at least one page');
			pagesListRef.current?.scrollIntoView({ behavior: 'smooth' });
			hasError = true;
		} else {
			setPagesError(undefined);
		}

		if (!selectedAdAccount || selectedAdAccount.id === undefined) {
			setAdAccountError('Please select an ad account option');
			adAccountsRef.current?.scrollIntoView({ behavior: 'smooth' });
			hasError = true;
		} else {
			setAdAccountError(undefined);
		}
		if (hasError) return;

		if (instagramPages != null) {
			await handleSaveSelectedPages(
				accessTokens,
				selectedPages,
				selectedAdAccount,
				saveAndPublish,
				selectedIGPages,
			);
		} else {
			await handleSaveSelectedPages(
				accessTokens,
				selectedPages,
				selectedAdAccount,
				saveAndPublish,
			);
		}

		if (shouldLaunch) {
			setShouldLaunch(false);
		}
	};

	if (!pages.length)
		return (
			<Flex direction="row" align="center" p={4} bg="#FEF3C7" borderRadius="lg">
				<Icon as={MdWarning} color="#D97706" mr={2} />
				<Text flex="1" fontSize="sm" color="#111827" fontWeight="500">
					You need at least one page associated, please create a Facebook page
					and reconnect your account clicking on &quot;Change account&quot;
				</Text>
				<Link href="https://www.facebook.com/pages/create" isExternal>
					<Button colorScheme="blue" size="sm">
						Create Facebook Page
					</Button>
				</Link>
			</Flex>
		);

	return (
		<Flex direction="column" w="full" mb={5} gap={5}>
			<div ref={pagesListRef}>
				<PagesList
					title="In which pages do you want to show your posts ?"
					facebookIcon={true}
					pages={pages}
					selectedPages={selectedPages}
					onSelect={handleSelectPage}
					error={pagesError}
				/>
			</div>
			{instagramPages && instagramPages.length > 0 && (
				<div ref={pagesListRef}>
					<PagesList
						title="In which accounts do you want to show your posts ?"
						pages={pages}
						instagramPages={instagramPages}
						selectedPages={selectedIGPages}
						onSelect={handleSelectIGPage}
						error={instagramPagesError}
					/>
				</div>
			)}
			<div ref={adAccountsRef}>
				<AdAccounts
					title="If you want paid ads, please select the ad account"
					accounts={adAccounts}
					selectedAccount={selectedAdAccount}
					onSelect={handleSelectAdAccount}
					isLoading={adAccountsLoading}
					error={adAccountError}
				/>
			</div>
			<Button
				w="fit-content"
				minW={15}
				alignSelf="flex-end"
				colorScheme="secondary"
				type="submit"
				isLoading={isSaving}
				loadingText="Saving..."
				onClick={handleSubmit}
			>
				{saveAndPublish ? 'Save and publish' : 'Save'}
			</Button>
		</Flex>
	);
};

export default SocialPages;
