import React, { RefObject, useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Stack,
	Text,
} from '@chakra-ui/react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
	StringInputHook,
} from 'src/components/common/form';
import AlertMessage from 'src/components/common/AlertMessage';
import { AppInputConfigContext } from 'src/contexts';
import {
	DetailsSchemas,
	ICatalogForm,
	ICatalogFormExtended,
	ICatalogKind,
	ICatalogKindField,
} from 'src/lib/schemas/commerce';
import { toastError } from 'src/services/toast';
import { renderInputWidget } from 'src/components/app/utils/renderInputWidget';
import { getValidationRules } from 'src/components/app/utils/getValidationRules';
import UserContext from 'src/contexts/UserContext';
import { ProductFormModalProvider } from 'src/contexts/ProductFormModalContext';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';

import { validateWebsiteUrl } from 'src/lib/utils';
import TagFormModal from '../tags/TagsFormModal';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { ITag } from 'src/services/tags';
import { uploadFile } from 'src/services/fileUpload';
import { isEmpty } from 'lodash';

interface ProductFormModalProps {
	isOpen: boolean;
	onClose: () => void;
	initialValues?: Partial<ICatalogForm>;
	onSubmit: (updatedPayload: any) => void;
	title: string;
	catalogKinds: ICatalogKind[];
}

const INITIAL_STATE: Partial<ICatalogForm> = {
	name: '',
	url: '',
	details: { description: '' as any, image: '' as any },
	category: undefined,
	kind: 'service',
	tags: [],
};

const getUrlErrorMessage = (kind: string): string => {
	return kind === 'product'
		? 'URL of your product is required'
		: 'URL of your service is required';
};

const createValidationSchemaForFields = (
	fields: ICatalogKindField[],
	kind: string,
) => {
	const schemaObject: { [key: string]: z.ZodTypeAny } = {};
	fields.forEach((field) => {
		let fieldSchema: z.ZodTypeAny = z.any();
		switch (field.type) {
			case 'String':
			case 'TextArea':
				fieldSchema = z.string();
				break;
			case 'Number':
				fieldSchema = z.number();
				break;
			case 'FlatImage': {
				fieldSchema = z.string().superRefine(async (url, ctx) => {
					const isRequired = !isEmpty(
						field.validations.filter((v) => v.id === 'required'),
					);
					const minShortestSide = 100;
					const minLongestSide = 400;
					try {
						await uploadFile(
							url,
							isRequired,
							undefined,
							undefined,
							minShortestSide,
							minLongestSide,
						);
					} catch (error: any) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: error.response?.data?.message || 'Logo URL is not valid',
						});
					}
				});
				break;
			}
			case 'Boolean':
				fieldSchema = z.boolean();
				break;
			default:
				fieldSchema = z.any();
		}

		field.validations.forEach((validation) => {
			if (validation.id === 'required' && validation.value === true) {
				if (field.property === 'url' && fieldSchema instanceof z.ZodString) {
					fieldSchema = fieldSchema
						.nonempty(getUrlErrorMessage(kind))
						.refine((url) => !/\s/.test(url), 'URL should not contain spaces');
				} else if (fieldSchema instanceof z.ZodString) {
					fieldSchema = fieldSchema.nonempty(`${field.name} is required`);
				} else if (fieldSchema instanceof z.ZodNumber) {
					fieldSchema = fieldSchema.min(1, `${field.name} is required`);
				}
			}
		});

		schemaObject[field.property] = fieldSchema;
	});

	return z.object(schemaObject);
};

const SpacedInputWrapper: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => <Box mb={2}>{children}</Box>;

const CustomRadioGroup: React.FC<{
	options: { label: string; value: string }[];
	value: string;
	onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
	const sortedOptions = [...options].sort((a, b) =>
		a.value === 'service' ? -1 : b.value === 'service' ? 1 : 0,
	);

	return (
		<RadioGroup value={value} onChange={onChange}>
			<Stack direction="row" spacing={5}>
				{sortedOptions.map((option) => (
					<Radio key={option.value} value={option.value}>
						<Text fontSize="14px" fontWeight="medium">
							{option.label}
						</Text>
					</Radio>
				))}
			</Stack>
		</RadioGroup>
	);
};

const ProductFormModal: React.FC<ProductFormModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	initialValues,
	title,
	catalogKinds,
}) => {
	let preparedValues = initialValues;
	const [prefilledValuesFlag, setPrefilledValuesFlag] =
		useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const tagModalToggle = useToggleWithPayload<Partial<ITag>>();
	const { account } = useContext(UserContext);
	const { inputConfig, loadingInputs, loadedInputs } = useContext(
		AppInputConfigContext,
	);
	const [selectedKind, setSelectedKind] = useState<string>(
		initialValues?.kind || 'service',
	);
	const [currentKind, setCurrentKind] = useState<ICatalogKind | undefined>();

	const [detailsSchemas, setDetailsSchemas] = useState<DetailsSchemas>({});

	const { fetchConfig: fetchTags, createConfig: createTagConfig } =
		useAccountConfigOptions('Tag');

	const prepareInitialValues = (
		initialValues?: any,
		catalogKindFields: ICatalogKindField[] = [],
	): Partial<ICatalogForm> | undefined => {
		if (!initialValues || prefilledValuesFlag) return undefined;

		const details: { [key: string]: any } = {};
		const staticValues: ICatalogFormExtended = {
			...initialValues,
			url: initialValues.url || account?.websiteLink,
		};
		catalogKindFields.forEach((field) => {
			if (Object.prototype.hasOwnProperty.call(initialValues, field.property)) {
				details[field.property] = staticValues[field.property];
				delete staticValues[field.property];
			}
		});

		if (staticValues.kind && staticValues.kind !== selectedKind) {
			handleKindChange(staticValues.kind);
		}

		return { ...staticValues, details };
	};

	const isSubMenuOpen = tagModalToggle.isOpen;

	useEffect(() => {
		if (initialValues && currentKind && !prefilledValuesFlag) {
			preparedValues = prepareInitialValues(initialValues, currentKind.fields);
			reset(preparedValues);
			if (selectedKind !== currentKind.kind) {
				handleKindChange(currentKind.kind);
			}
			setPrefilledValuesFlag(true);
		}
	}, [initialValues, currentKind]);

	const initialTagIds = initialValues?.tags?.map((tag) => tag._id);

	const initialTags =
		initialTagIds && initialTagIds.length > 0 && inputConfig['Tag']
			? inputConfig['Tag'].filter((tag) => initialTagIds.includes(tag.id))
			: [];

	const formMethods = useForm<ICatalogForm>({
		resolver: zodResolver(
			z.object({
				id: z.string().optional(),
				name: z.string().nonempty('Name is required'),
				kind: z.string().nonempty('Kind is required'),
				url: z
					.string()
					.nonempty(getUrlErrorMessage(selectedKind))
					.refine(
						(url) => validateWebsiteUrl(url).isValid && !/\s/.test(url),
						'Please enter a valid URL',
					),
				category: z.string().optional(),
				details: detailsSchemas[selectedKind] || z.object({}),
				tags: z.array(z.string()).optional(),
			}),
		),
		defaultValues: {
			...(preparedValues || initialValues),
			kind: INITIAL_STATE.kind,
			tags: initialTags || [],
		},
	});

	const {
		formState,
		reset,
		watch,
		setValue,
		clearErrors,
		control,
		setError: setFieldError,
	} = formMethods;

	const landingPageUrl = watch('url');

	const [tempDynamicValues, setTempDynamicValues] = useState<
		Record<string, any>
	>({});

	const handleKindChange = (newKind: string | null) => {
		if (newKind) {
			const currentValues = formMethods.getValues().details;
			setTempDynamicValues(currentValues);

			setSelectedKind(newKind);
		}
	};

	useEffect(() => {
		if (!selectedKind || !catalogKinds.length) return;

		const kind = catalogKinds.find((k) => k.kind === selectedKind);
		if (kind) {
			setCurrentKind(kind);
			const newSchema = createValidationSchemaForFields(
				kind.fields || [],
				selectedKind,
			);
			const newSchemas = { ...detailsSchemas, [kind.kind]: newSchema };
			setDetailsSchemas(newSchemas);

			const dynamicValues: any = {};
			kind.fields.forEach((field) => {
				if (tempDynamicValues[field.property]) {
					dynamicValues[field.property] = tempDynamicValues[field.property];
				} else if (initialValues?.details) {
					dynamicValues[field.property] =
						initialValues?.details[field.property] || '';
				}
			});

			formMethods.reset({
				...formMethods.getValues(),
				kind: kind.kind,
				details: dynamicValues,
			});
			formMethods.clearErrors();
		}
	}, [selectedKind, catalogKinds, formMethods, tempDynamicValues]);

	useEffect(() => {
		if (initialValues) {
			reset({
				...preparedValues,
				kind: initialValues.kind || INITIAL_STATE.kind,
				url: initialValues.url || account?.websiteLink,
			});
		} else {
			reset(INITIAL_STATE);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (loadingInputs['Tag'] || loadedInputs['Tag']) return;
		fetchTags();
	}, [loadingInputs, loadedInputs, fetchTags]);

	const handleClose = () => {
		if (formState.isSubmitting) return;
		reset(INITIAL_STATE);
		onClose();
		setPrefilledValuesFlag(false);
		setSelectedKind('service');
	};

	useEffect(() => {
		if (isOpen && initialValues) {
			const preparedValues = prepareInitialValues(
				initialValues,
				currentKind?.fields,
			);
			reset(preparedValues);
			setPrefilledValuesFlag(true);
		} else if (!isOpen) {
			setPrefilledValuesFlag(false);
			reset(INITIAL_STATE);
		}
	}, [isOpen, initialValues, currentKind, reset]);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [isOpen]);

	const handleLandingPageLinkBlur = () => {
		if (!landingPageUrl) return;

		const { isValid, updatedUrl } = validateWebsiteUrl(landingPageUrl);
		if (isValid) {
			setValue('url', updatedUrl, { shouldValidate: true });
			clearErrors('url');
		} else {
			setFieldError('url', {
				type: 'manual',
				message: 'Please enter a valid URL',
			});
		}
	};

	const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedUrl = e.target.value.replace(/\s/g, '');
		setValue('url', updatedUrl);
	};

	const handleSubmit = formMethods.handleSubmit(async (payload) => {
		if (!currentKind) {
			console.error('currentKind is not defined');
			return;
		}

		const fieldsFromCurrentKind = currentKind.fields.map(
			(field) => field.property,
		);

		const filteredDetails = Object.entries(payload.details)
			.filter(([key, _]) => fieldsFromCurrentKind.includes(key))
			.reduce<Record<string, any>>((acc, [key, value]) => {
				acc[key] = value;
				return acc;
			}, {});

		const { details, ...payloadWithoutDetails } = payload;

		const filteredPayload = {
			...payloadWithoutDetails,
			...filteredDetails,
			kind: selectedKind,
		};

		try {
			setError(null);
			await onSubmit(filteredPayload);
			reset(INITIAL_STATE);
			onClose();
		} catch (error: any) {
			const { message } = error.response.data;
			setError(message);
		}
	});

	const handleCreateTagOption = async (name: string) => {
		if (!name.trim()) {
			tagModalToggle.onOpen();
			return;
		}
		await handleCreateTag({ name });
	};

	const handleCreateTag = async (payload: Partial<ITag>) => {
		try {
			const newTag = await createTagConfig(payload);
			if (newTag && newTag.id) {
				const currentTags = formMethods.getValues('tags') || [];
				const updatedTags = [...currentTags, newTag.id];
				formMethods.setValue('tags', updatedTags);
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	const getUrlLabel = (): string => {
		if (selectedKind === 'product') {
			return 'URL of your product';
		}
		if (selectedKind === 'service') {
			return 'URL of your service';
		}
		return 'URL';
	};

	const requirementsLabel = (): string => {
		if (selectedKind === 'product') {
			return 'Select or create tags to identify this product';
		}
		if (selectedKind === 'service') {
			return 'Select or create tags to identify this service';
		}
		return 'Select or create tags';
	};

	const handleMenuOpen = (inputRef: RefObject<HTMLDivElement>) => {
		if (inputRef.current) {
			setTimeout(() => {
				inputRef?.current?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 100);
		}
	};

	const renderDynamicFields = () => (
		<FormControl>
			<Flex direction="column">
				{currentKind?.fields.map((field: ICatalogKindField) => {
					const validationRules = getValidationRules(field);
					const isStringType = field.type === 'String';
					const isMultiline = validationRules?.multiline?.value;
					const isTextAreaType =
						(isStringType && isMultiline) ||
						(validationRules.maxLength &&
							validationRules.maxLength.value >= 90);

					const InputWidget = renderInputWidget(
						isTextAreaType ? 'TextArea' : field.type,
					);

					const maxCharCount = validationRules?.maxLength?.value
						? validationRules?.maxLength?.value
						: undefined;

					if (InputWidget) {
						return (
							<SpacedInputWrapper key={field.property}>
								<InputWidget
									key={field.property}
									input={field}
									validationRules={validationRules}
									maxLength={maxCharCount}
									propertyPrefix={'details.'}
									defaultImageValue={initialValues?.image}
								/>
							</SpacedInputWrapper>
						);
					}
					return null;
				})}
			</Flex>
		</FormControl>
	);

	return (
		<>
			<Modal isOpen={isOpen && !isSubMenuOpen} onClose={handleClose} size="xl">
				<ModalOverlay zIndex={1800} />
				<ModalContent
					containerProps={{ zIndex: 1900 }}
					margin="auto"
					maxHeight="100vh"
				>
					<ModalHeader pb={2}>
						<Heading>{title}</Heading>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						pb={6}
						pr="4"
						css={{
							'&::-webkit-scrollbar': {
								width: '8px',
								backgroundColor: '#F5F5F5',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#888',
								borderRadius: '4px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#555',
							},
							overflowY: 'scroll',
						}}
					>
						<FormProvider {...formMethods}>
							<ProductFormModalProvider isProductFormModal={true}>
								<form onSubmit={handleSubmit}>
									<Flex direction="column" gap={6}>
										{error && (
											<AlertMessage status="error">{error}</AlertMessage>
										)}
										<FormControl>
											<Text mb={2} fontWeight="medium">
												Are you promoting a service or a product?{' '}
												<Text as="span" color="#e53e3e">
													*
												</Text>
											</Text>
											<Controller
												name="kind"
												control={control}
												render={({ field }) => (
													<CustomRadioGroup
														options={catalogKinds.map((kind) => ({
															label: kind.displayName,
															value: kind.kind,
														}))}
														value={field.value}
														onChange={(value) => {
															field.onChange(value);
															handleKindChange(value);
														}}
													/>
												)}
											/>
										</FormControl>
										<StringInputHook
											name="name"
											label="Name"
											placeholder="Enter name"
											required
										/>
										<StringInputHook
											name="url"
											label={getUrlLabel()}
											// requirementsLabel="Users will land here when they click on your ad"
											placeholder="https://"
											required
											formControlProps={{
												onBlur: handleLandingPageLinkBlur,
												onChange: handleUrlChange,
											}}
										/>
										{renderDynamicFields()}
										<MultiSelectCreateableInputHook
											name="tags"
											label="Tags (Optional)"
											// requirementsLabel={requirementsLabel()}
											placeholder="Select/search"
											options={inputConfig['Tag'] ?? []}
											isLoading={loadingInputs['Tag']}
											onCreateOption={handleCreateTagOption}
											isMulti
											onOpenMenu={handleMenuOpen}
										/>
										<MultiSelectValuesHook
											name="tags"
											title="Tags"
											options={inputConfig['Tag'] ?? []}
										/>
										<Flex justifyContent="right" gap={6}>
											<Button onClick={handleClose}>Cancel</Button>
											<Button
												colorScheme="secondary"
												isLoading={formState.isSubmitting}
												loadingText="Submitting..."
												type="submit"
											>
												Submit
											</Button>
										</Flex>
									</Flex>
								</form>
							</ProductFormModalProvider>
						</FormProvider>
					</ModalBody>
				</ModalContent>
			</Modal>

			<TagFormModal
				isOpen={tagModalToggle.isOpen}
				onClose={tagModalToggle.onClose}
				onSubmit={handleCreateTag}
				title="Add Tag"
				initialValues={tagModalToggle.payload}
			/>
		</>
	);
};

export default ProductFormModal;
