import { Box, Image, Text, VStack } from '@chakra-ui/react';
import EditIcon2 from 'src/assets/icons/EditIcon2';
import { ICatalog } from 'src/lib/schemas';

const IMAGE_PLACEHOLDER = '/images/image-placeholder.png';

interface IProductProps {
	product?: ICatalog;
	onClick: () => void;
	selected?: ICatalog;
}

const ProductCard = ({ product, onClick, selected }: IProductProps) => {
	return (
		<Box
			onClick={onClick}
			cursor="pointer"
			flex={1}
			mx="5px"
			mb="5px"
			textAlign="center"
			p={1}
			boxSizing="border-box"
			borderRadius="lg"
			maxW="200px"
		>
			<Box
				border="2px solid"
				borderColor={
					selected && product?.catalogId === selected.catalogId
						? 'orange.base'
						: 'transparent'
				}
				borderRadius="lg"
				w="200px"
				h="200px"
				maxW="200px"
				minW="200px"
				overflow="hidden"
				justifyContent="center"
				textAlign="center"
				alignContent="center"
				display="flex"
			>
				<Box
					position="relative"
					justifyContent="center"
					textAlign="center"
					alignContent="center"
				>
					{product ? (
						<Image
							src={
								product?.image && product.image !== ''
									? product.image
									: IMAGE_PLACEHOLDER
							}
							alt="Product"
							objectFit="contain"
							w="100%"
							h="100%"
						/>
					) : (
						<VStack
							bg="gray.100"
							p={4}
							justifyContent="center"
							w="200px"
							h="200px"
							maxW="300px"
						>
							<EditIcon2 w="50px" h="50px" ml={4} />
							<Text fontSize="16px" textAlign="center" mb={2}>
								Describe a custom product or service
							</Text>
						</VStack>
					)}
					{product?.isAIGeneratedImage && (
						<Text
							position="absolute"
							bottom={2}
							right={0}
							bg="rgba(255, 255, 255, 0.8)"
							color="orange.base"
							fontWeight="bold"
							p={1}
							borderLeftRadius="md"
							fontSize="13px"
						>
							AI Generated Image
						</Text>
					)}
				</Box>
			</Box>
			<Text fontSize="16px" fontWeight={500} mt={2} color="black">
				{product ? product.name : 'Define my own'}
			</Text>
		</Box>
	);
};

export default ProductCard;
