import { getCampaign, updateCampaignCreative } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';

interface handleSaveCreativeProps {
	editedCreative: ICreative;
	setCampaign: (campaign: any) => void;
	creativeModalToggle: { onClose: () => void };
	campaignId?: string;
}

export const handleSaveCreative = async ({
	editedCreative,
	setCampaign,
	creativeModalToggle,
	campaignId,
}: handleSaveCreativeProps) => {
	if (!campaignId) return;
	const payload = {
		attributes: editedCreative.attributes,
	};
	try {
		await updateCampaignCreative(campaignId, editedCreative.id, payload);
		const response = await getCampaign(campaignId);
		setCampaign(response);
		creativeModalToggle.onClose();
	} catch (error: any) {
		toastError(error);
	}
};
