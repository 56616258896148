import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	ModalProps,
} from '@chakra-ui/react';

interface CustomModalProps extends ModalProps {
	header?: React.ReactNode;
	children: React.ReactNode;
	footer?: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
	isOpen,
	onClose,
	header,
	children,
	footer,
	...props
}) => {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount {...props}>
			<ModalOverlay zIndex={1800} />
			<ModalContent
				maxH="90vh"
				containerProps={{
					zIndex: 1800,
				}}
				display="flex"
				flexDirection="column"
			>
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody overflowY="auto" flex="1" bg="gray.50" borderWidth="1px">
					{children}
				</ModalBody>
				<ModalFooter>{footer}</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CustomModal;
