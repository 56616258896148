export interface ICardData {
	icon: string;
	title: string;
	id: string;
	navigate: string;
	size?: 'sm' | 'lg';
}

const marketingData: ICardData[] = [
	{
		icon: 'images/home/multi-channels.png',
		title: 'All channels',
		id: 'all',
		navigate: '/projects/campaigns/new',
		size: 'lg',
	},
	{
		icon: 'images/home/facebook.png',
		title: 'Facebook',
		id: 'facebook',
		navigate: '/projects/campaigns/new',
	},
	{
		icon: 'images/home/instagram.png',
		title: 'Instagram',
		id: 'instagram',
		navigate: '/projects/campaigns/new',
	},
	{
		icon: 'images/home/twitter.png',
		title: 'Twitter',
		id: 'twitter',
		navigate: '/projects/campaigns/new',
		size: 'lg',
	},
	{
		icon: 'images/home/google.png',
		title: 'Google',
		id: 'google',
		navigate: '/projects/campaigns/new',
	},
	{
		icon: 'images/home/direct.png',
		title: 'Direct marketing',
		id: 'direct',
		navigate: '/projects/campaigns/new',
		size: 'lg',
	},
];

export default marketingData;
