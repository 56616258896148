import { createContext } from 'react';

interface ILayerAction {
	id?: string;
	arguments?: any;
}

interface ILayerSpec {
	name?: string;
	actions?: ILayerAction[];
}

export interface IVariation {
	id?: string;
	title?: string;
	isLoading?: boolean;
	layeredFile?: string;
	layerSpecs?: ILayerSpec[];
}

interface IPalette {
	strategy: {
		id: string;
	};
	options: Array<{
		name: string;
		colors: string[];
	}>;
}

interface ITemplateAttribute {
	industries?: string[];
	thumbnail?: string;
	palettes?: IPalette;
	fonts?: string[];
	tags?: string[];
}

interface IAccountInfo {
	folderName: string;
	existFolder: boolean;
}

export interface ITemplate {
	name?: string;
	id: string;
	enabled?: boolean;
	attributes?: ITemplateAttribute;
	variations?: IVariation[];
}

interface ITemplateContext {
	templates: ITemplate[];
	selectedTemplate: ITemplate | null;
	setSelectedTemplate: (template: ITemplate | null) => void;
}

interface IImageAttributes {
	dimensions: {
		width: string;
		height: string;
	};
	layeredFile: string;
	flatFile: string;
	layers: Array<any>;
}

export interface IUpdatePayload {
	variationId: string;
	imageType: string;
	logoUrl: string;
	attributes: {
		image: IImageAttributes;
	};
}

const TemplateContext = createContext<ITemplateContext>({
	templates: [],
	selectedTemplate: null,
	setSelectedTemplate: () => {},
});

export default TemplateContext;
