export enum ThreadTypesEnum {
	Campaign = 'CAMPAIGN',
}

export enum ThreadRolesEnum {
	User = 'user',
	Assistant = 'assistant',
}

export enum ChatMessageEnum {
	String = 'String',
	Suggestion = 'Suggestion',
}

export interface IAssistantCreativeData {
	id: string;
	channel: string;
	variant: string;
}

export interface IField {
	name: string;
	displayName: string;
	content: string;
	id: string;
	variant?: string;
	channel?: string;
	setValue: (field: string, value: string) => void;
}

export interface ISuggestion {
	value: string;
}

export interface ISplittedContent {
	value: string;
	type: string;
	suggestion: boolean;
}

export interface IMessage {
	threadId?: string;
	property?: string;
	role: string;
	splitted_content?: ISplittedContent[];
}

export interface IThread {
	threadId?: string;
	assistantId?: string;
	property?: string | number;
	reference?: string;
	sub_reference?: string;
	conversation: Array<IMessage>;
}

export interface IThreadPayload {
	user_message: string;
	current_content: string;
	scope: IGetThreadParams;
	thread_id?: string;
	property?: string | number;
}

export interface IThreadMessageParams {
	assistantId: string;
	payload: IThreadPayload;
	threadId?: string;
}

export interface IMessageResponse {
	response: {
		role: string;
		thread_id: string;
		property: string;
		suggestions?: ISuggestion;
		splitted_content?: ISplittedContent[];
	};
}

export interface IGetThreadParams {
	type: string;
	reference: string;
	sub_reference?: string;
	property?: any;
}

export interface IThreadResponse {
	scope: {
		thread_id: string;
		assistant_id: string;
	};
	conversation: Array<IMessage>;
}

export interface IOpenAssistantChat {
	fieldParams: IField;
	threadParams: IGetThreadParams;
	ref?: HTMLDivElement | null;
}
