import { Flex, Text, Icon, Card, Tooltip } from '@chakra-ui/react';
import {
	RiRefreshLine,
	RiEdit2Line,
	RiDeleteBin7Line,
	RiLockUnlockLine,
	RiLockLine,
} from 'react-icons/ri';

interface CardActionsProps {
	title: string;
	onEdit?: () => void;
	onRemove?: () => void;
	onRegenerate?: () => Promise<void>;
	onLock?: () => void;
	isSelected?: boolean;
	disabledActions?: boolean;
	isLocked?: boolean;
}

const CardActions = ({
	title,
	onEdit,
	onRegenerate,
	onRemove,
	onLock,
	isSelected,
	disabledActions,
	isLocked,
}: CardActionsProps) => {
	const disabledStyles = {
		opacity: 0.4,
		cursor: 'not-allowed',
	};

	return (
		<Card
			mb={4}
			boxShadow="md"
			maxH="sm"
			borderRadius="lg"
			bg={isLocked ? 'gray.200' : isSelected ? 'orange.base' : 'white'}
			py={0.5}
		>
			<Flex
				px={4}
				py={2}
				justifyContent="space-between"
				alignItems="center"
				color={isSelected ? 'white' : 'black'}
			>
				<Text size="md" color={isSelected ? 'white' : 'black'}>
					{title}
				</Text>
				<Flex gap={3} mb={-1}>
					{onEdit && (
						<Tooltip label="Edit" borderRadius={100} px={3}>
							<span>
								<Icon
									as={RiEdit2Line}
									onClick={!disabledActions ? onEdit : undefined}
									boxSize={5}
									cursor={disabledActions ? 'not-allowed' : 'pointer'}
									color={isSelected ? 'white' : 'black'}
									{...(disabledActions && disabledStyles)}
								/>
							</span>
						</Tooltip>
					)}

					{onRegenerate && (
						<Tooltip label="Regenerate" px={3} borderRadius={100}>
							<span>
								<Icon
									onClick={!disabledActions ? onRegenerate : undefined}
									as={RiRefreshLine}
									boxSize={5}
									cursor={disabledActions ? 'not-allowed' : 'pointer'}
									color={isSelected ? 'white' : 'black'}
									{...(disabledActions && disabledStyles)}
								/>
							</span>
						</Tooltip>
					)}
					{onRemove && (
						<Tooltip label="Remove" px={3} borderRadius={100}>
							<span>
								<Icon
									onClick={onRemove}
									as={RiDeleteBin7Line}
									boxSize={5}
									cursor="pointer"
									color={isSelected ? 'white' : 'black'}
								/>
							</span>
						</Tooltip>
					)}
					{onLock && (
						<Tooltip label={isLocked ? 'Unlock' : 'Lock'} px={3} borderRadius={100}>
							<span>
								<Icon
									onClick={onLock}
									as={isLocked ? RiLockLine : RiLockUnlockLine}
									boxSize={5}
									cursor="pointer"
									color={isSelected ? 'white' : 'black'}
								/>
							</span>
						</Tooltip>
					)}
				</Flex>
			</Flex>
		</Card>
	);
};

export default CardActions;
