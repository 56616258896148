import { z } from 'zod';

export const TestTemplateSchema = z
	.object({
		urlImageH: z.string().optional(),
		urlImageV: z.string().optional(),
		urlLogoLigth: z.string().optional(),
		urlLogoDark: z.string().optional(),
		designDirection: z.boolean(),
		fbStory: z.boolean(),
		fbFeed: z.boolean(),
		igFeed: z.boolean(),
		punchline: z.string().nonempty('punchline is required'),
		headline: z.string().nonempty('headline required'),
		callToAction: z.string().nonempty('call to action is required'),
	})
	.refine(
		(data) =>
			data.designDirection || data.fbStory || data.fbFeed || data.igFeed,
		{
			message:
				"At least one of 'designDirection', 'fbStory', 'fbFeed', or 'igFeed' must be true",
			path: ['designDirection', 'fbStory', 'fbFeed', 'igFeed'],
		},
	);

export interface ITemplateFormValues {
	urlImageH: string;
	urlImageV: string;
	urlLogoLigth: string;
	urlLogoDark: string;
	designDirection: boolean;
	fbStory: boolean;
	fbFeed: boolean;
	igFeed: boolean;
	punchline: string;
	headline: string;
	callToAction: string;
	code: string;
	promotionCode: string;
}
