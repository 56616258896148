import React, { useContext, useEffect, useState } from 'react';
import { Button, Text, Image, Box, Flex, VStack } from '@chakra-ui/react';
import { RiLockUnlockLine, RiLockLine } from 'react-icons/ri';
import { IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';
import { CampaignContext } from 'src/contexts';
import {
	editCampaignDesignDirections,
	getCampaign,
} from 'src/services/campaign';
import CustomModal from 'src/components/common/CustomModal';
import CardActions from '../creatives/CardActions';
import { cloneDeepWith } from 'lodash';

interface DesignLockModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	designDirections?: IDesignDirection[];
	setDesignDirections: React.Dispatch<React.SetStateAction<IDesignDirection[]>>;
}

const DesignLockModal: React.FC<DesignLockModalProps> = ({
	designDirections,
	setDesignDirections,
	isOpen,
	onSubmit,
	onClose,
}) => {
	const {
		id: campaignId,
		campaign,
		setCampaign,
		setOldCampaignCopy,
	} = useContext(CampaignContext);
	const [lockedOptions, setLockedOptions] = useState<string[]>([]);
	const [isHovering, setIsHovering] = useState<string | null>(null);
	const [amountToRegenerate, setAmountToRegenerate] = useState(3);

	useEffect(() => {
		if (designDirections) {
			const lockedIds = designDirections
				.filter((dd) => dd.locked)
				.map((dd) => dd.id);

			setLockedOptions(lockedIds);
		}
	}, [designDirections]);

	useEffect(() => {
		if (!designDirections?.length) return;

		const updatedDesignDirections = designDirections.map((dd) => ({
			...dd,
			locked: lockedOptions.includes(dd.id),
		}));

		const hasChanged = designDirections.some(
			(dd, index) => dd.locked !== updatedDesignDirections[index].locked,
		);

		if (hasChanged) {
			setDesignDirections(updatedDesignDirections);
			campaign &&
				setCampaign({
					...campaign,
					designDirections: updatedDesignDirections,
				});
		}
	}, [lockedOptions]);

	useEffect(() => {
		setAmountToRegenerate(Math.max(0, 3 - lockedOptions.length));
	}, [lockedOptions]);

	const isLocked = (id: string) => lockedOptions.includes(id);

	const toggleLock = async (id: string) => {
		if (!campaignId) return;
		setLockedOptions((prev) => {
			if (prev.includes(id)) {
				return prev.filter((lockedId) => lockedId !== id);
			} else {
				return [...prev, id];
			}
		});
		try {
			await editCampaignDesignDirections(campaignId, id, {
				locked: !isLocked(id),
			});
		} catch (error) {}
	};

	const handleSubmit = async () => {
		if (!campaign || !campaignId) return;
		onClose();
		setOldCampaignCopy(cloneDeepWith(campaign));
		const data = await getCampaign(campaignId);
		setCampaign(data);
		onSubmit();
	};

	const modalHeader = 'This change will affect your current designs';

	const modalFooter = (
		<>
			<Button variant="orangeOutline" onClick={onClose}>
				Cancel
			</Button>
			<Button variant="orangeSolid" ml={3} onClick={handleSubmit}>
				Continue
			</Button>
		</>
	);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
			header={modalHeader}
			footer={modalFooter}
		>
			<Text mb={4} color="black">
				Lock the designs that you want to keep by clicking on{' '}
				<Flex as="span" display="inline-flex">
					<RiLockUnlockLine />
				</Flex>
				<Text mt={1} color="black">
					We will regenerate up to {amountToRegenerate} designs.
				</Text>
			</Text>
			<Flex align="center" gap={2} wrap="wrap">
				{designDirections?.map((dd: any) => (
					<Box
						key={dd.id}
						textAlign="center"
						w="200px"
						position="relative"
						onMouseEnter={() => setIsHovering(dd.id)}
						onMouseLeave={() => setIsHovering(null)}
						cursor="pointer"
					>
						<Box position="relative">
							<Image
								src={dd.attributes.image.flatFile}
								alt={dd.name}
								mb={2}
								borderRadius="2xl"
								fallbackSrc="/fusion-loader.gif"
							/>

							{(isHovering === dd.id || isLocked(dd.id)) && (
								<Flex
									position="absolute"
									top={0}
									left={0}
									w="full"
									h="full"
									bg="blackAlpha.700"
									align="center"
									justify="center"
									borderRadius="2xl"
									zIndex={1}
									onClick={() => toggleLock(dd.id)}
								>
									<VStack>
										{isLocked(dd.id) ? (
											<>
												<RiLockLine color="white" size={35} />
												<Text color="white">Locked design</Text>
											</>
										) : (
											<>
												<RiLockLine color="white" size={35} />
												<Text color="white">Lock design</Text>
											</>
										)}
									</VStack>
								</Flex>
							)}
						</Box>

						<CardActions
							title={`Option ${dd.variant}`}
							onLock={() => toggleLock(dd.id)}
							isLocked={isLocked(dd.id)}
						/>
					</Box>
				))}
			</Flex>
		</CustomModal>
	);
};

export default DesignLockModal;
