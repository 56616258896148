import { Box, Flex, Heading, Skeleton } from '@chakra-ui/react';

interface IImageSkeleton {
	payloadLength: number;
}
export const ImageSkeleton = (props: IImageSkeleton) => {
	const { payloadLength } = props;
	const skeletonCountPerList = Math.ceil(payloadLength / 2);

	return (
		<Flex mt="40px" justifyContent="center" gap="50px">
			<Box>
				<Heading fontWeight="medium">Horizontal Images</Heading>

				{Array.from({ length: skeletonCountPerList }).map((_, index) => (
					<Skeleton
						key={`h-skeleton-${index}`}
						height="200px"
						mt="10px"
						width="200px"
						borderRadius="10px"
					/>
				))}
			</Box>

			<Box>
				<Heading fontWeight="medium">Vertical Images</Heading>

				{Array.from({ length: skeletonCountPerList }).map((_, index) => (
					<Skeleton
						key={`v-skeleton-${index}`}
						height="200px"
						mt="10px"
						width="200px"
						borderRadius="10px"
					/>
				))}
			</Box>
		</Flex>
	);
};
