import React, { useState, useEffect, FC, ReactNode, useContext } from 'react';
import TemplateContext, { ITemplate } from './TemplatesContext';
import {
	getTemplates,
	createTemplate as createTemplateApi,
	updateTemplate as updateTemplateApi,
} from 'src/services/templates';
import UserContext from 'src/contexts/UserContext';
import { toastError, toastSuccess } from 'src/services/toast';

interface TemplateProviderProps {
	children: ReactNode;
}

export const TemplateProvider: FC<TemplateProviderProps> = ({ children }) => {
	const { user } = useContext(UserContext);
	const [templates, setTemplates] = useState<ITemplate[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
		null,
	);

	useEffect(() => {
		if (!user) return;
		getTemplates(user.account)
			.then(setTemplates)
			.catch((error) => {
				toastError(error);
				console.error('Failed to fetch templates:', error);
			});
	}, [user]);

	return (
		<TemplateContext.Provider
			value={{ templates, selectedTemplate, setSelectedTemplate }}
		>
			{children}
		</TemplateContext.Provider>
	);
};

export default TemplateProvider;
