import { FC, useContext, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { IDesignDirection } from 'src/lib/schemas';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import UserContext from 'src/contexts/UserContext';
import { getCompanyAccount } from 'src/services/account';
import MediaCreativeModal from 'src/components/campaign/DesignOutput/parts/MediaCreativeModal';
import DDPhotoEditor from './DDPhotoEditor';

interface DDPreviewProps {
	textLayerContent?: any;
	designDirection: IDesignDirection;
	onRefetchDesignDirections: () => void;
	previewPSD: string;
	isChangingLayer: boolean;
	designerMode: boolean;
	setDesignerMode: (val: boolean) => void;
	closeEditModal: () => void;
}
const DDPreview: FC<DDPreviewProps> = ({
	textLayerContent,
	designDirection,
	onRefetchDesignDirections,
	previewPSD,
	isChangingLayer,
	designerMode,
	setDesignerMode,
	closeEditModal,
}) => {
	const { user } = useContext(UserContext);
	const [font, setFont] = useState<string | undefined>(undefined);
	useEffect(() => {
		getGlobalFont().then((font) => setFont(font));
	}, []);

	useEffect(() => {
		designDirectionFormToggle.onOpen(designDirection);
	}, [designerMode]);

	const getGlobalFont = async () => {
		if (!user) return;
		const { brandIdentity } = await getCompanyAccount(user.account);
		return brandIdentity?.fontType;
	};

	const designDirectionFormToggle =
		useToggleWithPayload<IDesignDirection | null>();

	const handleCloseDesignerMode = () => {
		designDirectionFormToggle.onClose();
		setDesignerMode(false);
	};

	return (
		<>
			<Flex direction="column" gap={4} flex={1}>
				<Flex
					p={4}
					direction="column"
					gap={4}
					borderRadius="md"
					bg="#F8F8F8"
					maxH="630px"
					boxShadow="0px 0.913px 5.478px 0px rgba(0, 0, 0, 0.15)"
				>
					{!designerMode && (
						<DDPhotoEditor
							key={previewPSD}
							layeredFile={previewPSD}
							textLayerContent={textLayerContent}
							font={font}
							designDirection={designDirection}
							isChangingLayer={isChangingLayer}
							onRefetchCreatives={onRefetchDesignDirections}
						/>
					)}
					{designDirectionFormToggle.payload && designerMode && (
						<MediaCreativeModal
							isOpen={designDirectionFormToggle.isOpen}
							data={designDirectionFormToggle.payload}
							onClose={handleCloseDesignerMode}
							onRefetchCreatives={onRefetchDesignDirections}
							layeredFile={previewPSD}
							textLayerContent={textLayerContent}
							font={font}
							closeEditModal={closeEditModal}
						/>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default DDPreview;
