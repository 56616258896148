import { extendTheme } from '@chakra-ui/react';

import {
	Button,
	Divider,
	Drawer,
	FormLabel,
	Heading,
	Input,
	Select,
	Text,
	Progress,
	Textarea,
	IconButton,
} from './customizedComponents';

const theme = extendTheme({
	fonts: {
		heading: `'Noto Sans','Open Sans', sans-serif, medium`,
		body: `'Noto Sans','Open Sans', sans-serif, medium`,
	},
	styles: {
		global: {
			html: {
				scrollBehavior: 'smooth',
			},
			body: {
				color: '#202223',
				bg: '#FFFFFF',
				fontFamily: `'Noto Sans','Open Sans', sans-serif, medium`,
			},
		},
	},
	semanticTokens: {
		colors: {
			primary: {
				default: '#F7480B',
			},
			secondary: {
				default: '#2C6ECB',
				main: '#2C6ECB',
				'50': '#f0f8fe',
				'100': '#deeefb',
				'200': '#c4e4f9',
				'300': '#9bd3f5',
				'400': '#6cbaee',
				'500': '#2c6ecb', // finalize and update palette
				'600': '#3582db',
				'700': '#2c6ecb',
				'800': '#2959a4',
				'900': '#264c82',
				'950': '#1c304f',
			},
			orange: {
				primary: '#F04D24',
				base: '#F7480B',
				light: '#F06D24',
				lighter: '#f7480b14',
				suggestion: {
					bg: '#ffdacd80',
					border: '#f7480b80',
				},
				header: '#f45923ed',
				logo: '#f04d23',
			},
			gray: {
				placeholder: '#A0AEC0',
				border: '#E2E8F0',
				text: '#626F86',
				subText: '#6F6C6C',
			},
			white: {
				suggestion: {
					bg: '#F9F9F9',
					border: '#9D9D9D',
				},
			},
			blue: {
				submit: '#2b6ecb',
			},
		},
	},
	components: {
		Button,
		Drawer,
		Divider,
		Heading,
		Input,
		Text,
		FormLabel,
		Select,
		Progress,
		Textarea,
		IconButton,
	},
});

export default theme;
