import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Button,
	Text,
	Flex,
	Collapse,
	Box,
	Icon,
	VStack,
	FormLabel,
	HStack,
	Spacer,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
	CommonAttributes,
	IChannelMediaAttributes,
	ICreative,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { renderCreativesByPlacement } from './utils/renderCreativesByPlacement';
import { formatLabel } from './utils/formatInputLabel';
import InputField from './components/InputField';
import { attributeConfig } from './utils/attributesConfig';
import { IChannelCreativeAttributes } from 'src/lib/schemas';
import { SelectSearchInput } from 'src/components/common/form';
import CustomModal from 'src/components/common/CustomModal';
import { AssistantChatContext } from 'src/contexts';
import MediaCreativeModal from 'src/components/campaign/DesignOutput/parts/MediaCreativeModal';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import UserContext from 'src/contexts/UserContext';
import { getCompanyAccount } from 'src/services/account';
import { IPerview } from 'src/components/campaign/DesignOutput/parts/DesignDirections/parts/utils';
import { cloneDeep, get } from 'lodash';
import DDPhotoEditor from '../design-directions/DDPhotoEditor';

interface EditCreativeModalProps {
	isOpen: boolean;
	onClose: () => void;
	handleSave: (creative: ICreative) => void;
	initialValues: ICreative;
	config?: IChannelCreativeAttributes;
	handleRefetchCreatives: () => void;
}

const EditCreativeModal: React.FC<EditCreativeModalProps> = ({
	isOpen,
	onClose,
	handleSave,
	initialValues,
	config,
	handleRefetchCreatives,
}) => {
	const [editedCreative, setEditedCreative] =
		useState<ICreative>(initialValues);
	const [designerMode, setDesignerMode] = useState(false);
	const [currentFieldIndex, setCurrentFieldIndex] = useState<number>(0);
	const [collapsedGroups, setCollapsedGroups] = useState<
		Record<string, boolean>
	>({});
	const { closeChat } = useContext(AssistantChatContext);
	const { user } = useContext(UserContext);
	const [font, setFont] = useState<string | undefined>(undefined);
	const [textLayerContent, setTextLayerContent] = useState<any>([]);
	const [currentCreative, setCurrentCreative] =
		useState<ICreative>(initialValues);
	const [isChangingImageLayer, setIsChangingImageLayer] = useState(false);
	const creativeFormToggle = useToggleWithPayload<ICreative>(editedCreative);
	const [previewPSDMeta, setPreviewPSDMeta] = useState<IPerview | undefined>(
		undefined,
	);

	const mediaContent =
		currentCreative?.attributes as unknown as IChannelMediaAttributes;

	useEffect(() => {
		const image = mediaContent?.image || {};

		if (currentCreative && image && image.layeredFile) {
			setPreviewPSDMeta((prevState) => ({
				...prevState,
				flatFile: image.flatFile,
				layeredFile: image.layeredFile,
				layers: image.layers,
			}));
		}
	}, [mediaContent?.image?.layeredFile]);

	useEffect(() => {
		getGlobalFont().then((font) => setFont(font));
	}, []);

	useEffect(() => {
		creativeFormToggle.onOpen(editedCreative);
	}, [designerMode]);

	const getGlobalFont = async () => {
		if (!user) return;
		const { brandIdentity } = await getCompanyAccount(user.account);
		return brandIdentity?.fontType;
	};

	const handleCloseEditModal = () => {
		closeChat();
		onClose();
	};

	const handleCloseDesignerMode = () => {
		creativeFormToggle.onClose();
		setDesignerMode(false);
		handleCloseEditModal();
	};
	const CTAOptions =
		config?.creatives
			?.find((c) => c.app === initialValues.app)
			?.attributes?.find((att) => att.property === 'callToAction')?.options ??
		[];

	const handleChangeTextLayerContent = useCallback(() => {
		document.getElementById('change-text-layer-button-preview')?.click();
	}, []);

	const handleLayersTextChange = useCallback(
		(content: any, layers: any) => {
			const cleanContent = cloneDeep(content);
			const hasNestedObject = (obj: any): boolean => {
				return Object.values(obj).some((val) => typeof val === 'object');
			};

			hasNestedObject(cleanContent) &&
				Object.keys(cleanContent).forEach((key) => {
					if (key.includes('.')) delete cleanContent[key];
				});
			const layersCopy = [...(mediaContent?.image?.layers ?? [])];
			const newLayers: any = [];
			layers.forEach((layer: any) => {
				let currentLayer: any = layer;
				const parents = [];
				while (currentLayer.parentName) {
					const parentLayer = layersCopy.find(
						(layer) => layer.name === currentLayer.parentName,
					);
					if (parentLayer) parents.unshift(currentLayer.parentName);
					currentLayer = parentLayer;
				}

				const newLayer = {
					name: layer.name,
					content: get(cleanContent, layer.name),
					parents,
				};
				newLayers.push(newLayer);
			});
			setTextLayerContent(newLayers);
			handleChangeTextLayerContent();
		},
		[mediaContent],
	);

	const handleChange = (value: string, field: string) => {
		setEditedCreative((prevState) => {
			if (field.includes('[')) {
				const [key, index] = field.split(/\[|\]/).filter(Boolean);
				const arrayField = (prevState as any).attributes[
					key as keyof CommonAttributes
				];

				if (Array.isArray(arrayField)) {
					const updatedArray = [...arrayField];
					updatedArray[parseInt(index)] = value;

					setCurrentFieldIndex(parseInt(index));

					return {
						...prevState,
						attributes: {
							...prevState.attributes,
							[key]: updatedArray,
						},
					};
				}
			}

			return {
				...prevState,
				attributes: {
					...prevState.attributes,
					[field as keyof CommonAttributes]: value,
				},
			};
		});
	};

	const toggleCollapse = (key: string) => {
		setCollapsedGroups((prevState) => ({
			...prevState,
			[key]: !prevState[key],
		}));
	};

	const renderInputField = (
		key: string,
		value: any,
		config: any,
		maxLength?: number,
	) => {
		if (config.inputType === 'dropdown' && CTAOptions) {
			return (
				<Box key={key}>
					<FormLabel>{config.label}</FormLabel>
					<SelectSearchInput
						name={key}
						placeholder="Select/search"
						options={CTAOptions.map((opt) => ({
							label: opt.value,
							value: opt.id,
						}))}
						value={(editedCreative.attributes as any)[key]}
						onChange={(value) => handleChange(String(value), key)}
					/>
				</Box>
			);
		}

		return (
			<InputField
				key={key}
				label={config.label}
				value={String(value)}
				onChange={(value) => handleChange(value, key)}
				isTextarea={config.inputType === 'textarea'}
				maxLength={maxLength}
				withAssistant
				name={key}
				creativeData={initialValues}
			/>
		);
	};

	const renderInputsBasedOnAttributes = (
		validationConfig?: IChannelCreativeAttributes,
	) => {
		const fields =
			(initialValues as any).attributes.output ?? initialValues.attributes;

		return Object.keys(fields).map((key) => {
			if (key === 'image') return;
			// 	{
			// 	const layers = fields?.image.layers;

			// 	const filteredMediaLayers = layers?.filter(
			// 		(layer: any) => layer.type === 'image' && layer.primary,
			// 	) as ImageLayer[];

			// 	const filteredTextLayers = layers?.filter(
			// 		(layer: any) =>
			// 			layer.type === 'text' && layer.primary && layer.visible,
			// 	) as TextLayer[];

			// 	return (
			// 		<Box key={key}>
			// 			{filteredTextLayers.map((layer, index) => (
			// 				<InputField
			// 					key={layer.id || layer.name}
			// 					creativeData={initialValues}
			// 					label={layer.displayName}
			// 					value={layer.content}
			// 					onChange={(value) => handleChange(value, `${key}[${index}]`)}
			// 					name={`${key}[${index}]`}
			// 				/>
			// 			))}
			// 		</Box>
			// 	);
			// }

			const attribute = validationConfig?.creatives[0].attributes.find(
				(att) => att.property === key,
			);

			const maxLength = attribute?.validations.find(
				(validation) => validation.id === 'String.maxLength',
			)?.value;

			const maxLengthNumber =
				typeof maxLength === 'number' ? maxLength : undefined;

			const value = (editedCreative as any).attributes[
				key as keyof CommonAttributes
			];
			const config = attributeConfig[key as keyof typeof attributeConfig] || {
				label: formatLabel(key),
				inputType: 'string',
			};

			if (Array.isArray(value)) {
				return (
					<Box
						key={key}
						p={2}
						borderRadius="md"
						onClick={() => toggleCollapse(key)}
						cursor="pointer"
					>
						<Flex justifyContent="space-between">
							<FormLabel mb={0}>{config.label}</FormLabel>
							<Icon
								boxSize={5}
								aria-label={`Toggle ${key}`}
								as={collapsedGroups[key] ? ChevronDownIcon : ChevronUpIcon}
							/>
						</Flex>
						<Collapse in={!collapsedGroups[key]} animateOpacity>
							<VStack spacing={2} mt={2} align="stretch">
								{value.map((item, index) => (
									<InputField
										key={`${key}-${index}`}
										value={item}
										onChange={(value) =>
											handleChange(value, `${key}[${index}]`)
										}
										maxLength={maxLengthNumber}
										withAssistant
										name={`${key}[${index}]`}
										creativeData={initialValues}
									/>
								))}
							</VStack>
						</Collapse>
					</Box>
				);
			}

			return renderInputField(key, value, config, maxLengthNumber);
		});
	};

	const modalHeader = (
		<HStack pr={10}>
			<Text size="md">
				<Text as="span" size="md" fontWeight="bold">
					Editing:{' '}
				</Text>
				Option {initialValues?.variant} - {initialValues?.name}
			</Text>
			<Spacer />
			{previewPSDMeta && (
				<Button
					size="sm"
					variant="blankOutline"
					onClick={() => setDesignerMode(true)}
				>
					Switch to designer mode
				</Button>
			)}
		</HStack>
	);

	const modalFooter = (
		<>
			<Button onClick={handleCloseEditModal} mr={3}>
				Close
			</Button>
			<Button
				// isLoading={isLoading}
				onClick={() => handleSave(editedCreative)}
				variant="orangeSolid"
			>
				Save changes
			</Button>
		</>
	);
	return (
		<CustomModal
			isOpen={isOpen}
			onClose={handleCloseEditModal}
			size="4xl"
			header={modalHeader}
			footer={modalFooter}
			trapFocus={false}
		>
			{designerMode && previewPSDMeta && creativeFormToggle.payload ? (
				<MediaCreativeModal
					isOpen={creativeFormToggle.isOpen}
					data={creativeFormToggle.payload}
					onClose={handleCloseDesignerMode}
					onRefetchCreatives={handleRefetchCreatives}
					layeredFile={previewPSDMeta.layeredFile}
					textLayerContent={textLayerContent}
					font={font}
					closeEditModal={handleCloseEditModal}
					isCreative
				/>
			) : (
				<Flex gap={5}>
					<Box
						flex={1}
						maxH="70vh"
						overflowY="auto"
						pr={4}
						pl={2}
						sx={{
							'&::-webkit-scrollbar': {
								width: '10px',
							},
							'&::-webkit-scrollbar-track': {
								background: '#f1f1f1',
								borderRadius: '8px',
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#888',
								borderRadius: '8px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								background: '#555',
							},
						}}
					>
						<VStack spacing={4} align="stretch">
							{renderInputsBasedOnAttributes(config)}
						</VStack>
					</Box>
					<Flex flex={1}>
						{renderCreativesByPlacement({
							creatives: [editedCreative],
							withActions: false,
							size: 'sm',
							fieldIndex: currentFieldIndex,
							layeredFile: previewPSDMeta?.layeredFile,
							textLayerContent,
							font,
							designDirection: editedCreative,
							isChangingLayer: isChangingImageLayer,
							onRefetchCreatives: handleRefetchCreatives,
						})}
					</Flex>
				</Flex>
			)}
		</CustomModal>
	);
};

export default EditCreativeModal;
