import http from './http';
import { ITemplate } from 'src/contexts/templates/TemplatesContext';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

export const updateTemplate = async (
	template: ITemplate,
	templateid: string,
) => {
	const { data } = await http.patch<ITemplate>(
		`${accountUrl}/templates/${templateid}`,
		template,
	);
	return data;
};

export const createTemplate = async (template: ITemplate) => {
	if (!template.id) {
		template.id = 'No title';
	}

	const { data } = await http.post<ITemplate>(
		`${accountUrl}/templates`,
		template,
	);
	return data;
};

export const getTemplates = async (account: string) => {
	try {
		const { data } = await http.get<ITemplate[]>(`${accountUrl}/templates`);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates');
	}
};

export const getFonts = async () => {
	try {
		const { data } = await http.get<string[]>(`${accountUrl}/getFonts`);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates');
	}
};

export const getTemplateById = async (
	account: string,
	templateId: string,
): Promise<ITemplate> => {
	try {
		const { data } = await http.get<ITemplate>(
			`${accountUrl}/templates/${templateId}`,
		);
		return data;
	} catch (error: any) {
		throw new Error(`Failed to fetch template ${templateId}`);
	}
};
