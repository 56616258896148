import { FC, useContext, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import axios from 'axios';
import { isArray } from 'lodash';
import PhotoEditor from 'src/components/common/PhotoEditor';
import { CampaignContext } from 'src/contexts';
import config from 'src/config';
import {
	genrateCampaignCreatives,
	getCampaign,
	updateCampaignCreative,
} from 'src/services/campaign';
import auth from 'src/services/auth';
import http from 'src/services/http';
import { DownloadIcon, UploadIcon } from 'src/assets/icons';
import { customToast, toastError, toastSuccess } from 'src/services/toast';
import {
	IChannelMediaAttributes,
	ICreative,
	IDesignDirection,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas';
import { downloadFile, parseCampaignName } from 'src/lib/utils';

interface IMediaCreativeModalProps {
	data: ICreative | IDesignDirection;
	isOpen: boolean;
	onClose: () => void;
	onRefetchCreatives: () => void;
	layeredFile?: string;
	textLayerContent?: any;
	font?: string | undefined;
	closeEditModal?: () => void;
	isCreative?: boolean;
}

const LAYERS_NAMES = ['Background', 'Product', 'Headline'];
interface Variables {
	[key: string]: string;
}

const findVariables = (layers: (ImageLayer | TextLayer)[]): Variables => {
	const variables: Variables = {};
	layers.forEach((layer) => {
		const l = layer as ImageLayer;
		if (l.type === 'image' && l.imageUrl) {
			variables[l.name] = l.imageUrl;
		}
	});
	return variables;
};

const MediaCreativeModal: FC<IMediaCreativeModalProps> = ({
	data,
	isOpen,
	onClose,
	onRefetchCreatives,
	layeredFile: previewLayeredFile,
	textLayerContent,
	font,
	closeEditModal,
	isCreative = false,
}) => {
	const [isEditorLoading, setIsEditorLoading] = useState(true);
	const [showOverlay, setShowOverlay] = useState(true);
	const [showPendingProgress, setShowPendingProgress] = useState(false);
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [documentCount, setDocumentCount] = useState(0);
	const [fileToSave, setFileToSave] = useState<ArrayBuffer | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [scheduleClose, setSchedueClose] = useState(false);
	const [isTextLoaded, setIsTextLoaded] = useState(false);
	const [error, setError] = useState<string | null>(null);
	useState<IChannelMediaAttributes | null>(null);
	const [isDownloading, setIsDownloading] = useState(false);
	const [regenerateAll, setRegenerateAll] = useState(!isCreative);

	const { id: campaignId, campaign, setCampaign } = useContext(CampaignContext);

	const { variant, attributes, name, id: creativeId } = data;
	const attributesData = attributes as unknown as IChannelMediaAttributes;
	const {
		layeredFile: orignalLayeredFile,
		dimensions: { width, height },
	} = attributesData.image;

	const [layeredFile, setLayeredFile] = useState(
		previewLayeredFile ?? orignalLayeredFile,
	);

	const isInvalidNumberOfDocuments = documentCount !== 1;

	useEffect(() => {
		if (isUploadingFile || !fileToSave) return;
		handleUploadFile(fileToSave);
		setIsUploadingFile(true);
	}, [isUploadingFile, fileToSave]);

	useEffect(() => {
		if (showOverlay && !isInvalidNumberOfDocuments) setShowOverlay(false);
	}, [documentCount, showOverlay]);

	useEffect(() => {
		if (isInvalidNumberOfDocuments && isSubmitted)
			setError(
				'To save the changes, please keep open only the tab that you want to save.',
			);
		else if (!isInvalidNumberOfDocuments && isSubmitted) {
			setError(null);
		}
	}, [isInvalidNumberOfDocuments, isSubmitted]);

	useEffect(() => {
		if (isSubmitting && !isEditorLoading) setShowPendingProgress(true);
	}, [isSubmitting, isEditorLoading]);

	const handleSubmit = async () => {
		setIsSubmitted(true);
		if (isInvalidNumberOfDocuments) return;

		const creativeId = data.id;
		if (!campaignId || !creativeId) return;

		setIsSubmitting(true);
		handleTriggerPSDSave();

		if (regenerateAll) {
			try {
				await genrateCampaignCreatives(campaignId, creativeId);
			} catch (error) {
				toastError('Failed to regenerate creatives');
			}
		}
	};

	useEffect(() => {
		if (scheduleClose && !showPendingProgress) {
			setSchedueClose(false);
			onClose();
		}
	}, [scheduleClose, showPendingProgress]);

	const handleFailedUpdating = () => {
		setFileToSave(null);
		setIsUploadingFile(false);
		setIsSubmitting(false);
	};

	const handleTriggerPSDSave = () => {
		const psdSaveButtonElem = document.getElementById('psd-save-button');
		if (psdSaveButtonElem) psdSaveButtonElem.click();
	};

	const handleUploadFile = async (ab: ArrayBuffer) => {
		const fileBlob = new Blob([ab]);
		const formData = new FormData();
		formData.append('f', fileBlob, `${new Date().getTime()}.psd`);

		const baseURL = config.app.apiURL.split('/v1')[0];
		const uploadUrl = `${baseURL}/v1/user-storage/upload?fileExtension=psd&directory=edit-image`;
		const tokens = auth.getTokens();
		const headers = {
			'Content-Type': `multipart/form-data`,
			Authorization: `Bearer ${tokens?.access.token}`,
		};

		axios({
			method: 'post',
			url: uploadUrl,
			data: formData,
			headers,
		})
			.then(({ data }) => {
				handleGenerateManifest(data);
			})
			.catch((error) => {
				toastError(error);
				handleFailedUpdating();
			});
	};

	// /v2/apps/fusion_ai.generate_image_psd/execute/process_psd   // new end point
	// /v2/apps/fusion_ai.image_converter/execute/convert         // old end point
	const handleGenerateManifest = async (layeredFile: string) => {
		const variables = findVariables(attributesData.image.layers);
		http
			.post('/v2/apps/fusion_ai.generate_image_psd/execute/process_psd', {
				inputs: {
					layeredFile,
					variables,
					templateId: creativeId,
					variantId: variant,
					newPsd: 'true',
				},
			})
			.then(({ data }) => {
				processCallback(data.callback);
			})
			.catch((error) => {
				toastError(error);
				handleFailedUpdating();
			});
	};

	const processCallback = async (callbackUrl: string) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl), 500);
			} else if (status === 'error' || status === 'failed') {
				console.error(response.data);
				customToast(JSON.stringify(response.data), 'error');
				handleFailedUpdating();
			} else if (status === 'successful') {
				const updatePayload = {
					attributes: {
						...attributesData,
						image: {
							dimensions: {
								width: String(body.dimensions.width),
								height: String(body.dimensions.height),
							},
							layeredFile: body.layeredFile,
							flatFile: body.flatFile,
							layers: body.layers,
						},
					},
				};
				if (!campaignId || !creativeId) {
					customToast('Campaign or creative ID is missing', 'error');
					handleFailedUpdating();
					return;
				}

				await updateCampaignCreative(campaignId, creativeId, updatePayload);
				await onRefetchCreatives();

				if (regenerateAll) {
					try {
						await genrateCampaignCreatives(campaignId, creativeId);
						const data = await getCampaign(campaignId);
						setCampaign(data);
						toastSuccess('Creatives have been regenerated successfully');
					} catch (error) {
						toastError('Failed to regenerate creatives');
					}
				}

				setIsSubmitting(false);
				setIsUploadingFile(false);
				setSchedueClose(true);
				closeEditModal && closeEditModal();
			}
		} catch (e) {
			toastError(e);
		}
	};

	const handleDownload = async (ab: ArrayBuffer) => {
		setIsDownloading(true);
		const fileBlob = new Blob([ab]);
		if (!fileBlob) {
			customToast('No file to download', 'error');
			return;
		}

		const fileName = `${parseCampaignName(campaign?.title ?? '')}-${
			data.variant
		}-${data.channel}-${width}x${height}`;
		const link = document.createElement('a');
		link.href = URL.createObjectURL(fileBlob);
		link.setAttribute('download', `${fileName}.psd`);
		document.body.appendChild(link);
		link.click();
		link?.parentNode?.removeChild(link);
		setIsDownloading(false);
	};

	const handleTriggerPSDDownload = async () => {
		const fileName = `${parseCampaignName(campaign?.title ?? '')}-${
			data.variant
		}-${data.channel}-${width}x${height}`;
		if (showOverlay && !layeredFile) await downloadFile(layeredFile, fileName);
		else {
			const psdDownloadButtonElem = document.getElementById(
				'psd-download-button',
			);
			if (psdDownloadButtonElem) psdDownloadButtonElem.click();
		}
	};

	useEffect(() => {
		if (
			!showOverlay &&
			!isTextLoaded &&
			isArray(textLayerContent) &&
			textLayerContent.length > 0
		) {
			handleChangeTextLayerContent();
		}
	}, [showOverlay, isTextLoaded, textLayerContent]);

	const handleChangeTextLayerContent = () => {
		document.getElementById('change-text-layer-button-edit-mode')?.click();
		setIsTextLoaded(true);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="6xl">
			<ModalOverlay zIndex={1800} />
			<ModalContent
				margin="auto"
				containerProps={{
					zIndex: 1800,
				}}
			>
				<ModalCloseButton />
				<ModalBody
					borderRadius="md"
					bg="white"
					boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
					px={4}
					pb={4}
					pt={10}
				>
					<Flex gap={8}>
						<Flex
							flex={5}
							direction="column"
							borderRadius="md"
							bg="#F8F8F8"
							gap={2}
							px={4}
							py={2}
							pb={4}
						>
							<Flex justify="space-between">
								<Flex gap={1}>
									<Text>{variant}</Text>
									<Text>|</Text>
									<Text transform="capitalize">{name}</Text>
									<Text>|</Text>
									<Text>
										{width}x{height}
									</Text>
								</Flex>
								<Flex justify="right" gap={4} alignItems="center">
									<Tooltip
										label="Download"
										borderRadius={100}
										px={3}
										zIndex="2000"
									>
										<Button
											colorScheme="secondary"
											px={0}
											h={10}
											isDisabled={isEditorLoading || isSubmitting}
											onClick={handleTriggerPSDDownload}
											isLoading={isDownloading}
										>
											<DownloadIcon color="#fff" />
										</Button>
									</Tooltip>

									<Tooltip
										label="Upload"
										borderRadius={100}
										px={3}
										zIndex="2000"
									>
										<Button
											htmlFor="file-input"
											as="label"
											colorScheme="secondary"
											px={0}
											h={10}
											isDisabled={
												isDownloading || isEditorLoading || isSubmitting
											}
										>
											<UploadIcon color="white" />
										</Button>
									</Tooltip>
								</Flex>
							</Flex>

							{error && (
								<Text color="#f74809" align="right">
									{error}
								</Text>
							)}

							<PhotoEditor
								input={{ files: [layeredFile] }}
								isEditorLoading={isEditorLoading}
								onSave={(ab) => setFileToSave(ab)}
								onDownload={(ab) => handleDownload(ab)}
								showOverlay={showOverlay}
								onLoadingFinished={() => setIsEditorLoading(false)}
								onDocumentCount={(count) => setDocumentCount(count)}
								isSubmitting={isSubmitting}
								showPendingProgress={showPendingProgress}
								onPendingProgressFinished={(val) => setShowPendingProgress(val)}
								handleClearErrors={() => setError(null)}
								headlineText={textLayerContent}
								font={font}
							/>

							<Flex
								justifyContent={isCreative ? 'end' : 'space-between'}
								alignItems="center"
								mt={4}
								w="full"
								borderTop="1px solid #E2E8F0"
								pt={4}
							>
								{!isCreative && (
									<Checkbox
										isChecked={regenerateAll}
										fontSize="14px"
										onChange={(e) => setRegenerateAll(e.target.checked)}
										size="md"
										sx={{
											'& .chakra-checkbox__label': {
												fontSize: '14px',
											},
											'& .chakra-checkbox__control[data-checked]': {
												backgroundColor: '#F7480B',
												borderColor: '#F7480B',
											},
										}}
									>
										Also regenerate content for all the channels
									</Checkbox>
								)}

								<Button
									variant="orangeSolid"
									isLoading={isSubmitting}
									loadingText="Saving..."
									isDisabled={isInvalidNumberOfDocuments && isSubmitted}
									onClick={handleSubmit}
								>
									Save changes
								</Button>
							</Flex>
						</Flex>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default MediaCreativeModal;
