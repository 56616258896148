import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Checkbox,
	Input,
	Text,
	Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ITemplateFormValues } from 'src/lib/schemas/account/template/TemplateSchema';

const TableComponent = () => {
	const { watch, register, setValue } = useFormContext<ITemplateFormValues>();

	const urlImageH = watch('urlImageH');
	const urlImageV = watch('urlImageV');
	const urlLogoLigth = watch('urlLogoLigth');
	const urlLogoDark = watch('urlLogoDark');
	const code = watch('code');
	const promotionCode = watch('promotionCode');
	const useAspectRatio = (imageUrl: string | undefined) => {
		const [dimensions, setDimensions] = useState<
			{ width: number; height: number } | undefined
		>(undefined);

		useEffect(() => {
			if (!imageUrl) {
				setDimensions(undefined);
				return;
			}

			const img = new Image();
			img.src = imageUrl;
			img.onload = () => {
				setDimensions({ width: img.width, height: img.height });
			};
			img.onerror = () => {
				setDimensions(undefined);
			};

			return () => {
				setDimensions(undefined);
			};
		}, [imageUrl]);

		return dimensions;
	};

	const dimensionsH = useAspectRatio(urlImageH);
	const dimensionsV = useAspectRatio(urlImageV);

	const gcd = (a: number, b: number): number => {
		return b === 0 ? a : gcd(b, a % b);
	};

	const calculateAspectRatioText = (
		dimensions: { width: number; height: number } | undefined,
	) => {
		if (!dimensions) return '';
		const { width, height } = dimensions;

		const divisor = gcd(width, height);

		const aspectRatioWidth = width / divisor;
		const aspectRatioHeight = height / divisor;

		return `${aspectRatioWidth}:${aspectRatioHeight}`;
	};

	const [tableData, setTableData] = useState<
		{
			name: string;
			type: string;
			value: string;
			key: keyof ITemplateFormValues;
			aspectRatio?: string | null;
			enabled: boolean;
		}[]
	>([
		{
			name: 'Product image Horizontal',
			type: 'Image',
			value: urlImageH,
			key: 'urlImageH',
			aspectRatio: calculateAspectRatioText(dimensionsH),
			enabled: true,
		},
		{
			name: 'Product image vertical',
			type: 'Image',
			value: urlImageV,
			key: 'urlImageV',
			aspectRatio: calculateAspectRatioText(dimensionsV),
			enabled: true,
		},
		{
			name: 'logo ligth',
			type: 'Image',
			value: urlLogoLigth,
			key: 'urlLogoLigth',
			enabled: true,
		},
		{
			name: 'logo Dark',
			type: 'Image',
			value: urlLogoDark,
			key: 'urlLogoDark',
			enabled: true,
		},
		{
			name: 'code',
			type: 'Text',
			value: code,
			key: 'code',
			enabled: false,
		},
		{
			name: 'description',
			type: 'Text',
			value: promotionCode,
			key: 'promotionCode',
			enabled: false,
		},
	]);

	const handleCheckboxChange = (
		index: number,
		checked: boolean,
		key: keyof ITemplateFormValues,
	) => {
		const updatedTableData = tableData.map((row, rowIndex) =>
			rowIndex === index ? { ...row, enabled: checked } : row,
		);
		setTableData(updatedTableData);

		if (!checked) {
			setValue(key, '');
		}
	};

	return (
		<Box mt="50px">
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th>
							<Text>Case</Text>
						</Th>
						<Th>
							<Text>Enable</Text>
						</Th>
						<Th>
							<Text>Type</Text>
						</Th>
						<Th>
							<Text>Name</Text>
						</Th>
						<Th>
							<Text>Value</Text>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{tableData.map((row, index) => (
						<Tr key={index}>
							<Td>{index + 1}</Td>
							<Td>
								<Checkbox
									isChecked={row.enabled}
									onChange={(e) =>
										handleCheckboxChange(index, e.target.checked, row.key)
									}
								/>
							</Td>
							<Td>{row.type}</Td>
							<Td>{row.name}</Td>
							<Td>
								<Input
									placeholder="Enter value"
									{...register(row.key)}
									defaultValue={row.value}
									isDisabled={!row.enabled}
									style={{
										backgroundColor: !row.enabled ? '#f0f0f0' : 'white',
										color: !row.enabled ? '#a0a0a0' : 'black',
										border: !row.enabled ? '1px solid #ccc' : '1px solid #000',
									}}
								/>
							</Td>
							<Td>{row.aspectRatio || ''}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default TableComponent;
