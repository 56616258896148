import { FormProvider, useForm } from 'react-hook-form';
import { useContext, useEffect, useRef, useState } from 'react';
import {
	Button,
	Flex,
	Switch,
	FormLabel,
	Tooltip,
	Text,
	Spinner,
	Box,
} from '@chakra-ui/react';
import { RxOpenInNewWindow } from 'react-icons/rx';
import http from 'src/services/http';
import TemplatesContext, {
	IUpdatePayload,
	IVariation,
} from 'src/contexts/templates/TemplatesContext';
import { StringInputHook } from 'src/components/common/form';
import CustomContainer from 'src/components/common/CustomContainer';
import { ImageListTemplates } from './ImageListTemplates';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	ITemplateFormValues,
	TestTemplateSchema,
} from 'src/lib/schemas/account/template/TemplateSchema';
import { ImageSkeleton } from './commponents/ImageSkeleton';
import { customToast } from 'src/services/toast';
import TableComponent from './commponents/TestTable';

interface TestTemplatesProps {
	textButton: string;
}
const TestTemplates = ({ textButton }: TestTemplatesProps) => {
	const [activeVariations, setActiveVariations] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [payloadLength, setPayloadLength] = useState<number>();
	const [payloads, setPayloads] = useState<IUpdatePayload[]>([]);
	const { selectedTemplate, setSelectedTemplate } =
		useContext(TemplatesContext);
	const endOfPageRef = useRef<HTMLDivElement | null>(null);

	const formMethods = useForm<ITemplateFormValues>({
		defaultValues: {
			urlImageH:
				'https://styleguide.iu.edu/images/3-2_placeholder_768px-512px.png',
			urlImageV:
				'https://astrotowing.ca/wp-content/uploads/2020/08/Vertical-Placeholder-Image.jpg',
			urlLogoLigth:
				'https://www.goomlandscapes.co.nz/wp-content/uploads/2018/08/logo-placeholder.png',
			urlLogoDark:
				'https://dieselpunkcore.com/wp-content/uploads/2014/06/logo-placeholder.png',
			designDirection: true,
			fbStory: true,
			fbFeed: true,
			igFeed: true,
			punchline: 'Unleash Your Style',
			headline: "Summer's Calling",
			callToAction: 'Shop Now',
			code: '',
			promotionCode: '',
		},
		resolver: zodResolver(TestTemplateSchema),
	});
	const {
		handleSubmit,
		register,
		setValue,
		watch,
		formState: { errors },
	} = formMethods;

	const [allVariations, setAllVariations] = useState([
		{ id: 'design', layeredFile: '' },
		{ id: 'facebook_story', layeredFile: '' },
		{ id: 'facebook_feed', layeredFile: '' },
		{ id: 'instagram_feed', layeredFile: '' },
	]);

	useEffect(() => {
		if (selectedTemplate) {
			const initialVariations =
				selectedTemplate.variations?.filter((variation) =>
					[
						'design',
						'facebook_story',
						'facebook_feed',
						'instagram_feed',
					].includes(variation.id!),
				) || [];

			setActiveVariations(initialVariations);

			setAllVariations((prevVariations) =>
				prevVariations.map((variation) => {
					const matchingVariation = initialVariations.find(
						(v) => v.id === variation.id,
					);

					if (matchingVariation) {
						return {
							...variation,
							layeredFile: matchingVariation.layeredFile || '',
						};
					}
					return variation;
				}),
			);
		}
	}, [selectedTemplate]);

	useEffect(() => {
		if (payloads.length === payloadLength) {
			setLoading(false);
		}
		if (loading && endOfPageRef.current) {
			endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [payloads, payloadLength]);

	const getVariationById = (id: string) => {
		return allVariations.find((v) => v.id === id);
	};

	const variationIdMap: Record<string, string> = {
		designDirection: 'design',
		fbStory: 'facebook_story',
		fbFeed: 'facebook_feed',
		igFeed: 'instagram_feed',
	};

	const handleSwitchChange = (name: string) => {
		const id = variationIdMap[name];

		const currentValue = watch(name as keyof ITemplateFormValues);
		setValue(name as keyof ITemplateFormValues, !currentValue);

		const updatedVariation = getVariationById(id);

		if (!updatedVariation) {
			return;
		}

		if (!currentValue) {
			const newActiveVariations = [...activeVariations, updatedVariation];

			setActiveVariations(newActiveVariations);
		} else {
			const newActiveVariations = activeVariations.filter(
				(variation) => variation.id !== updatedVariation.id,
			);

			setActiveVariations(newActiveVariations);
		}
	};

	const onSubmit = (data: ITemplateFormValues) => {
		handleGenerateManifestForVariations(data);
	};

	const handleGenerateManifestForVariations = async (
		data: ITemplateFormValues,
	) => {
		if (
			!selectedTemplate?.variations ||
			selectedTemplate.variations.length === 0
		) {
			return;
		}

		setLoading(true);

		setPayloads([]);

		setPayloadLength(activeVariations.length);

		for (const variation of activeVariations) {
			setPayloadLength(activeVariations.length * 4);

			await makeApiCall(variation, 'H', 'logoDark', data);

			await makeApiCall(variation, 'V', 'logoLigth', data);

			await makeApiCall(variation, 'V', 'logoDark', data);
			await makeApiCall(variation, 'H', 'logoLigth', data);
		}
	};

	const makeApiCall = async (
		variation: IVariation,
		imageType: string,
		logoType: string,
		data: ITemplateFormValues,
	) => {
		const templateWithSingleVariation = {
			...selectedTemplate,
			variations: [variation],
		};

		const variables: any = {
			campaign: {
				promotions: [
					{
						code: data.code || '',
						description: data.promotionCode || '',
					},
				],
			},
			account: {
				generateImage: 'true',
				logo:
					logoType == 'logoDark'
						? data.urlLogoDark || ''
						: data.urlLogoLigth || '',
			},
			direction: {
				punchline: data.punchline,
				headline: data.headline,
				callToAction: data.callToAction,
			},
		};

		const inputs = {
			variantId: variation.id,
			template: templateWithSingleVariation,
			variables: variables,
			layers: [
				{
					name: '$Product',
					imageUrl:
						imageType === 'H' ? data.urlImageH || '' : data.urlImageV || '',
				},
			],
		};

		try {
			const { data: responseData } = await http.post(
				'/v2/apps/fusion_ai.generate_image_psd/execute/process_psd',
				{ inputs },
			);

			await processCallback(
				responseData.callback,
				variation.id!,
				imageType,
				logoType,
				data,
			);
		} catch (error) {
			customToast('Error processing images', 'error');
		}
	};

	let errorShown = false;

	const processCallback = async (
		callbackUrl: string,
		variationId: string,
		imageType: string,
		logoType: string,
		data: ITemplateFormValues,
	) => {
		const response = await http.get(callbackUrl);
		const { status, body } = response.data;
		if (status === 'processing' || status === 'pending') {
			setTimeout(
				processCallback.bind(
					null,
					callbackUrl,
					variationId,
					imageType,
					logoType,
					data,
				),
				3500,
			);
		} else if (status === 'error' || status === 'failed') {
			if (!errorShown) {
				console.error(`Error processing ${variationId}:`, status);
				customToast('Error processing images', 'error');
				errorShown = true;
			}
			setLoading(false);
		} else if (status === 'successful') {
			const logoUrl =
				logoType == 'logoDark' ? data.urlLogoDark : data.urlLogoLigth;

			const updatePayload: IUpdatePayload = {
				variationId,
				imageType,
				logoUrl,
				attributes: {
					image: {
						dimensions: {
							width: String(body.dimensions.width),
							height: String(body.dimensions.height),
						},
						layeredFile: body.layeredFile,
						flatFile: body.flatFile,
						layers: body.layers,
					},
				},
			};

			setPayloads((prevPayloads) => [...prevPayloads, updatePayload]);
		}
	};

	const getLabel = (name: keyof ITemplateFormValues) => {
		switch (name) {
			case 'designDirection':
				return 'Design Direction';
			case 'fbStory':
				return 'FB/IG story';
			case 'fbFeed':
				return 'Facebook Feed';
			case 'igFeed':
				return 'Instagram Feed';
		}
	};

	const isVariaitonsDisabled =
		!selectedTemplate?.variations || selectedTemplate.variations.length === 0;

	return (
		<>
			<CustomContainer mt="80px">
				<Box mt="10px">
					<Text fontWeight="bold" fontSize="24px">
						Test templates
					</Text>
					<Button
						textAlign="center"
						variant="link"
						colorScheme="blue"
						onClick={() =>
							window.open(
								'/tasks/fusion_ai.image_generator?category=65d5e6e691a129f951e102c8',
								'_blank',
							)
						}
					>
						Generate image with AI
						<RxOpenInNewWindow style={{ marginLeft: '4px' }} />
					</Button>

					<FormProvider {...formMethods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Flex gap={20} justifyContent="space-around">
								<Flex
									direction="column"
									alignItems="start"
									justifyContent="start"
									width="30%"
								>
									<Box margin="15px 30px 15px 0">
										<FormLabel htmlFor="enabled-switch" mb="0">
											<Text>Variations</Text>
										</FormLabel>
									</Box>
									<Flex
										direction="column"
										gap={4}
										justifyContent="start"
										alignItems="start"
									>
										{(
											[
												'designDirection',
												'fbStory',
												'fbFeed',
												'igFeed',
											] as Array<
												'designDirection' | 'fbStory' | 'fbFeed' | 'igFeed'
											>
										).map((name, index) => (
											<Box key={name}>
												<Tooltip
													label={
														isVariaitonsDisabled
															? 'Should generate variations'
															: ''
													}
													isDisabled={!isVariaitonsDisabled}
													placement="top"
													hasArrow
													bg="red.600"
													color="white"
												>
													<div>
														<Switch
															id={`enabled-switch-${name}`}
															isChecked={Boolean(watch(name))}
															onChange={() => handleSwitchChange(name)}
															isDisabled={isVariaitonsDisabled}
															sx={{
																'& .chakra-switch__track': {
																	backgroundColor: watch(name)
																		? '#F7480B'
																		: 'gray.200',
																},
																'& .chakra-switch__thumb': {
																	backgroundColor: 'white',
																},
															}}
														/>
														<FormLabel
															htmlFor={`enabled-switch-${index}`}
															mb="0"
															marginLeft="10px"
														>
															<Text> {getLabel(name)}</Text>
														</FormLabel>
													</div>
												</Tooltip>
											</Box>
										))}
										{!isVariaitonsDisabled && activeVariations.length <= 0 && (
											<Text color="red.500" fontSize="sm">
												Should select at least one variation
											</Text>
										)}
									</Flex>
								</Flex>
								<Flex
									direction="column"
									alignItems="start"
									justifyContent="start"
									width="30%"
								>
									<Box margin="15px 30px 15px 0">
										<Text mb="0">Directions</Text>
									</Box>
									<Flex
										direction="column"
										gap={4}
										justifyContent="start"
										alignItems="start"
									>
										<Flex
											display="flex"
											justifyContent="center"
											alignItems="center"
											gap="5px"
										>
											<StringInputHook
												name="punchline"
												placeholder="Unleash Your Style"
											/>
											<Box width="100px">
												<Text fontSize="12px">Punchline</Text>
											</Box>
										</Flex>

										<Flex
											display="flex"
											justifyContent="center"
											alignItems="center"
											gap="5px"
										>
											<StringInputHook
												name="headline"
												placeholder="Summer's Calling"
											/>
											<Box width="100px">
												<Text fontSize="12px">Headline</Text>
											</Box>
										</Flex>

										<Flex
											display="flex"
											justifyContent="center"
											alignItems="center"
											gap="5px"
										>
											<StringInputHook
												name="callToAction"
												placeholder="Shop Now"
											/>
											<Box width="100px">
												<Text fontSize="12px">Call to Action</Text>
											</Box>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
							<Box mt="50px">
								<TableComponent />
							</Box>
							{
								<Flex justifyContent="center">
									{loading &&
										payloads.length > 0 &&
										payloads.length < payloadLength! && (
											<ImageSkeleton payloadLength={payloadLength!} />
										)}
								</Flex>
							}

							{payloads.length >= payloadLength! && (
								<ImageListTemplates
									payloads={payloads}
									payloadLength={payloadLength!}
								/>
							)}
							<Flex
								justifyContent="flex-end"
								mt={20}
								gap={10}
								ref={endOfPageRef}
							>
								<Tooltip
									label={
										isVariaitonsDisabled ? 'Should generate variations' : ''
									}
									isDisabled={!isVariaitonsDisabled}
									placement="top"
									hasArrow
									bg="red.600"
									color="white"
								>
									<Button
										disabled={isVariaitonsDisabled}
										sx={{
											backgroundColor: isVariaitonsDisabled
												? '#F7480B'
												: '#F7480B',
											color: 'white',
											opacity: isVariaitonsDisabled ? 0.6 : 1,
										}}
										_hover={{
											bg: isVariaitonsDisabled ? '#F7480B' : '#e64109',
										}}
										type="submit"
									>
										{loading ? (
											<>
												<Spinner size="sm" mr={2} />
											</>
										) : (
											'Test'
										)}
									</Button>
								</Tooltip>
								<Button colorScheme="blue" type="submit" form="hook-form">
									{textButton}
								</Button>
							</Flex>
						</form>
					</FormProvider>
				</Box>
			</CustomContainer>
		</>
	);
};

export default TestTemplates;
