import {
	Flex,
	Text,
	FormLabel,
	Skeleton,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { FC } from 'react';
import { ICatalog } from 'src/lib/schemas';
import ProductCard from './Product';

const SAMPLE_PROD_URL =
	'https://storage.googleapis.com/public-fusion-images/soap-bottle-mockup.jpg';

interface ICatalogList {
	isLoading: boolean;
	isProductByAILoading: boolean;
	products: ICatalog[];
	selected?: ICatalog;
	onAddProduct: () => void;
	onSelect: (product: ICatalog) => void;
}

const ProductList: FC<ICatalogList> = ({
	products,
	selected,
	isLoading,
	isProductByAILoading,
	onAddProduct,
	onSelect,
}) => {
	const gotSampleProduct = selected?.image === SAMPLE_PROD_URL;
	const renderText = () => {
		if (isLoading) return;
		return !products.length
			? 'Well… We tried but we could not find any product in your website. You can still preview your ad with a sample product'
			: `Below we show the first ${products.length} products that we found, once you create the account you can import all of them`;
	};

	const renderedProducts = products.map((product) => (
		<WrapItem key={product.catalogId}>
			<ProductCard
				product={product}
				onClick={() => onSelect(product)}
				selected={selected}
			/>
		</WrapItem>
	));

	return (
		<Flex direction="column">
			<Flex direction="column">
				<Flex justify="space-between" alignItems="center">
					<FormLabel>
						<Flex gap={1} alignItems="center" fontSize="16px">
							What do you want to promote?
						</Flex>
						{/* <Flex>
							<Text fontSize="12px" color="#A0AEC0">
								{renderText()}
							</Text>
						</Flex> */}
					</FormLabel>
				</Flex>
			</Flex>
			<Wrap justify="start">
				{!isLoading ? (
					<>
						{renderedProducts}
						{isProductByAILoading && (
							<WrapItem mx="5px">
								<Skeleton borderRadius="lg" h="200px" w="200px" />
							</WrapItem>
						)}
						<WrapItem>
							<ProductCard onClick={onAddProduct} />
						</WrapItem>
					</>
				) : (
					Array(4)
						.fill(null)
						.map((_, index) => (
							<WrapItem key={index} mx="5px" mb="6px">
								<Skeleton borderRadius="lg" h="200px" w="200px" />
							</WrapItem>
						))
				)}
			</Wrap>
		</Flex>
	);
};

export default ProductList;
