import { FC, useContext, useEffect, useState } from 'react';
import { Button, Divider, Flex, Image } from '@chakra-ui/react';
import { DownloadIcon, UploadIcon, LockIcon } from 'src/assets/icons';
import { ImageLayer, TextLayer } from 'src/lib/schemas';
import { customToast, toastError } from 'src/services/toast';
import { CampaignContext } from 'src/contexts';
import { delay, downloadFile, parseCampaignName } from 'src/lib/utils';
import Overlay from 'src/components/common/Overlay';
import http from 'src/services/http';
import { uploadFile } from 'src/services/fileUpload';

interface IPerview {
	flatFile: string;
	layeredFile: string;
	layers: (ImageLayer | TextLayer)[];
}

interface DDMediaFieldProps {
	layer: ImageLayer;
	variant: string;
	isLocked?: boolean;
	previewPSD: IPerview;
	onChangePreviewPSD?: (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => void;
	isChangingLayer: boolean;
	onChangingLayer: (status: boolean) => void;
}

const DDMediaField: FC<DDMediaFieldProps> = ({
	layer,
	variant,
	isLocked,
	previewPSD,
	onChangePreviewPSD,
	onChangingLayer,
	isChangingLayer,
}) => {
	const {
		name,
		displayName,
		imageUrl,
		dimensions: { width, height },
	} = layer;
	const accept = '.png, .jpg, .jpeg, .svg, .webp, .gif';
	const [isUploading, setIsUploading] = useState(false);
	const [isDownloading, setDownloading] = useState(false);
	const [fileToSave, setFileToSave] = useState<File | undefined>(undefined);
	const [imageSrc, setImageSrc] = useState<string | undefined>(imageUrl);
	const { campaign } = useContext(CampaignContext);

	useEffect(() => {
		setImageSrc(imageUrl);
	}, [imageUrl]);

	useEffect(() => {
		if (isUploading || !fileToSave) return;
		handleUploadFile(fileToSave);
	}, [fileToSave]);

	const cleanS3ImageUrl = (url: string) => {
		const baseUrl = url.split('?')[0];
		if (baseUrl.match(/\.(jpg|jpeg|png|gif)$/i)) return baseUrl;
		else return '';
	};

	const handleDownload = async () => {
		setDownloading(true);
		if (!imageSrc) {
			customToast('No file to download', 'error');
			return;
		}

		const fileName = `${parseCampaignName(
			campaign?.title ?? '',
		)}-design-direction-${variant}-${name}-${width}x${height}`;
		try {
			await downloadFile(cleanS3ImageUrl(imageSrc), fileName);
		} catch (error) {
			toastError(error);
		} finally {
			setDownloading(false);
		}
	};

	const handleUploadFile = async (file: File) => {
		if (!isValidFile(file)) {
			customToast(
				`Unsupported file format. Only .png and .jpg allowed.`,
				'error',
			);
			return;
		}

		setIsUploading(true);
		onChangingLayer(true);

		try {
			let uploadedImage;
			if (layer.name === '$account.logo') {
				const minWidth = 100;
				const minHeight = 30;
				uploadedImage = await uploadFile(file, true, minWidth, minHeight);
			} else if (layer.name === '$promotedObject.image') {
				//TODO: this configuration should be taken from catalog Kind
				const minShortestSide = 100;
				const minLongestSide = 400;
				uploadedImage = await uploadFile(
					file,
					true,
					undefined,
					undefined,
					minShortestSide,
					minLongestSide,
				);
			} else {
				uploadedImage = await uploadFile(file, true);
			}

			changeLayer(uploadedImage.imageUrl);
		} catch (error) {
			toastError(error);
			setIsUploading(false);
			onChangingLayer(false);
		}
	};

	const changeLayer = async (image: string) => {
		const changeLayerPayload = {
			inputs: {
				layeredFile: previewPSD.layeredFile,
				values: [
					{
						layer: layer.name,
						property: 'image',
						value: image,
					},
				],
			},
		};

		try {
			const { data } = await http.post(
				'/v2/apps/fusion_ai.edit_psd/execute/edit_psd',
				changeLayerPayload,
			);
			if (data) await processCallback(data.callback, image);
		} catch (error) {
			toastError(error);
			setIsUploading(false);
			onChangingLayer(false);
		}
	};

	const processCallback = async (callbackUrl: string, image: string) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl, image), 500);
			} else if (status === 'error' || status === 'failed') {
				customToast('Error processing image', 'error');
				onChangingLayer(false);
				setIsUploading(false);
			} else if (status === 'successful') {
				const updatedLayer = { ...layer, imageUrl: image };
				const filterdLayers = previewPSD.layers.filter(
					(layer) => layer.name !== name,
				);
				const layers = [...filterdLayers, updatedLayer];
				onChangePreviewPSD?.(body.flatFile, body.layeredFile, layers);
				delay(100);
				setImageSrc(image);
				onChangingLayer(false);
				setIsUploading(false);
			}
		} catch (e) {
			toastError(e);
			onChangingLayer(false);
			setIsUploading(false);
		}
	};

	const isValidFile = (file: File): boolean => {
		const allowedExtensions = Array.from(
			new Set(accept.split(',').map((ext) => ext.trim().substring(1))),
		);
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		return fileExtension ? allowedExtensions.includes(fileExtension) : false;
	};

	return (
		<Flex gap={4} direction="column">
			<Flex fontSize="14px">{displayName}</Flex>
			<Flex gap={4} justify="space-between" alignItems="start" wrap="wrap">
				<Flex
					flex={1}
					justify="center"
					alignItems="center"
					minW="120px"
					maxW="120px"
					position="relative"
				>
					{isLocked && <LockedImageOverlay />}

					<Image src={imageSrc} maxW="120px" h="auto" />
				</Flex>

				<Flex flex={2} gap={2} direction="column" justify="left" mt="60px">
					<Flex alignItems="center" wrap="wrap">
						<Button
							bg="transparent"
							p={0}
							m={0}
							onClick={handleDownload}
							isDisabled={isUploading}
							isLoading={isDownloading}
						>
							<DownloadIcon width="22px" height="25px" color="#3182CE" />
						</Button>
						<Button
							htmlFor={`upload-image${name}`}
							as="label"
							cursor="pointer"
							bg="transparent"
							p={0}
							m={0}
							isDisabled={isLocked || isChangingLayer}
							isLoading={isUploading}
						>
							<input
								type="file"
								id={`upload-image${name}`}
								accept={accept}
								hidden
								onChange={(e) => {
									setFileToSave(e.target.files?.[0]);
								}}
							/>
							<UploadIcon width="22px" height="25px" color="#3182CE" />
						</Button>
					</Flex>
				</Flex>
			</Flex>
			<Divider color="#CACACA" my={6} />
		</Flex>
	);
};

const LockedImageOverlay = () => {
	return (
		<Overlay
			borderRadius="18px"
			children={
				<Flex w="full" h="full" justify="center" align="center">
					<LockIcon color="white" w={6} h={6} />
				</Flex>
			}
		/>
	);
};

export default DDMediaField;
