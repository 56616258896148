import React from 'react';
import { Box, Text, VStack, Image } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ICardData } from '../utils/marketinData';
import 'src/styles/hover.css';

interface CardProps {
	card: ICardData;
}

const Card: React.FC<CardProps> = ({ card }) => {
	const { navigate, icon, id, title, size } = card;
	const navigateTo = useNavigate();
	const location = useLocation();

	const handleNavigation = () => {
		const state = location.state || {};
		navigateTo(navigate, { state: { ...state, selectedChannel: id } });
	};

	return (
		<Box
			className="container"
			borderWidth="1px"
			borderRadius="lg"
			w="full"
			maxW="200px"
			mx="auto"
			textAlign="center"
			onClick={handleNavigation}
			cursor="pointer"
			transition="border-color 0.3s ease-in-out"
			_hover={{
				borderColor: 'orange.base',
			}}
		>
			<Box
				className="content"
				bg="gray.50"
				height="60%"
				width="100%"
				borderTopRadius="lg"
				transition="background-color 0.3s ease-in-out"
			>
				<VStack spacing={4} py={4} height="100%" justifyContent="center">
					<Image src={icon} alt={id} w={size === 'lg' ? '4em' : '3em'} />
				</VStack>
			</Box>
			<Box p={2} mt={2}>
				<Text>{title}</Text>
			</Box>
		</Box>
	);
};

export default Card;
