import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';

import StringInput, { StringInputProps } from './StringInput';
import {
	IAssistantCreativeData,
	IField,
	IGetThreadParams,
	ThreadTypesEnum,
} from 'src/lib/schemas';
import { AssistantChatContext, CampaignContext } from 'src/contexts';

export interface StringInputHookProps<T extends FieldValues>
	extends StringInputProps {
	requirementsLabel?: string;
	name: FieldPath<T>;
	setFieldValue?: (field: string, value: string) => void;
	withAssistant?: boolean;
	displayName?: string;
	creativeData?: IAssistantCreativeData;
	withErrorMessage?: boolean;
	errorMessage?: string;
	direction?: 'row' | 'column';
	requirementsLabelStyle?: React.CSSProperties;
}

function StringInputHook<T extends FieldValues = FieldValues>({
	name,
	setFieldValue,
	requirementsLabel,
	withAssistant,
	displayName,
	creativeData,
	withErrorMessage,
	requirementsLabelStyle,
	errorMessage,
	direction,
	...props
}: StringInputHookProps<T>) {
	const [isFocusSet, setIsFocusSet] = useState(true);
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext();
	const { id: campaignId } = useContext(CampaignContext);
	const { openAssistantChat } = useContext(AssistantChatContext);

	const { field, formState } = useController({ name, control });
	const { onChange, value } = field;

	const error = get(errors, name);

	useEffect(() => {
		setIsFocusSet(true);
	}, [formState.isSubmitting]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (error) setIsFocusSet(false);
		onChange(e);
	};

	const registerProps = register(name);

	const handleOpenAssistantChat = async (ref?: HTMLDivElement) => {
		const threadParams: IGetThreadParams = {
			type: ThreadTypesEnum.Campaign,
			reference: campaignId!,
			sub_reference: creativeData?.id ?? name,
			property: props.id ?? name,
		};
		const fieldParams: IField = {
			displayName: props.label ?? displayName ?? '',
			name,
			variant: creativeData?.variant,
			channel: creativeData?.channel,
			content: value,
			id: props.id ?? name,
			setValue: setFieldValue!,
		};
		openAssistantChat({ threadParams, fieldParams, ref });
	};

	return (
		<StringInput
			id={name}
			requirementsLabelStyle={requirementsLabelStyle} 
			error={errorMessage && error?.message ? errorMessage : error?.message}
			requirementsLabel={requirementsLabel}
			valueLength={value?.length ?? 0}
			handleOpenAssistantChat={handleOpenAssistantChat}
			withAssistant={withAssistant}
			{...props}
			inputProps={{
				...props.inputProps,
				...registerProps,
				onChange: (e: any) => {
					registerProps.onChange(e);
					props.inputProps?.onChange?.(e);
					handleChange(e);
				},
			}}
			isFocusSet={isFocusSet}
			withErrorMessage={withErrorMessage}
			direction={direction}
		/>
	);
}

export default StringInputHook;
