import {
	Box,
	Flex,
	useDisclosure,
	Text,
	Heading,
	SimpleGrid,
	VStack,
	Button,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import UserContext from 'src/contexts/UserContext';
import { isEmpty } from 'lodash';
import Container from 'src/components/common/Container';
import DomainForm from '../scraping/DomainForm';
import ChannelCard from './components/ChannelCard';
import marketingData from './utils/marketinData';
import { Campaigns } from '../projects';
import GettingStartedModal from './GettingStartedModal';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import NothingToSee from 'src/components/common/NothingToSee';
import BannerCarousel from './components/BannerCarousel';
import banners from './utils/banners';
import FusionLoading from 'src/components/common/FusionLoading';

const Home = () => {
	const [showFirstCampaignBanner, setShowFirstCampaignBanner] = useState(false);
	const [areCampaignsLoaded, setAreCampaignsLoaded] = useState(false);
	const [hasCampaigns, setHasCampaigns] = useState(false);
	const [bottomCardsHeight, setBottomCardsHeight] = useState(0);
	const navigate = useNavigate();
	const { account } = useContext(UserContext);
	const leftRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleResize = () => {
			if (leftRef.current) {
				setBottomCardsHeight(leftRef.current.clientHeight);
			}
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	});

	const {
		isOpen: isGettingStartedOpen,
		onOpen: onGettingStartedOpen,
		onClose: onGettingStartedClose,
	} = useDisclosure();

	const hasCompletedForm =
		!isEmpty(account?.name) && !isEmpty(account?.industry);

	const handleSubmitData = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		setShowFirstCampaignBanner(true);
	};

	return (
		<>
			<Flex w="full" direction="column" gap={5}>
				<BannerCarousel images={banners} />
				<Flex
					w={{ base: 'full', lg: '75%', '2xl': '60%' }}
					alignSelf="center"
					gap={5}
					direction="column"
				>
					{!hasCompletedForm && !showFirstCampaignBanner && (
						<Container withGradientBorder>
							<Heading color="gray.900">Tell us about your business</Heading>
							<Text>
								This will allow FusionAds.ai to generate ads more accurately
								according to your business.
							</Text>
							<Box mt={3} w="full">
								<DomainForm withManualOption onDataSubmit={handleSubmitData} />
							</Box>
						</Container>
					)}
					{showFirstCampaignBanner && (
						<Container withGradientBorder>
							<Flex justify="space-between" w="full" alignItems="center">
								<VStack alignItems="flex-start">
									<Heading color="gray.900">Thank you</Heading>
									<Text>
										You can now generate your first campaign to promote your
										business
									</Text>
								</VStack>
								<Button
									variant="orangeSolid"
									onClick={() => navigate('/projects/campaigns/new')}
								>
									Promote my business
								</Button>
							</Flex>
						</Container>
					)}
					<Flex gap={5}>
						<Container>
							<Box ref={leftRef} w="full">
								<Heading mb={5}>
									Where do you want to promote your business?
								</Heading>
								<SimpleGrid
									columns={{ sm: 1, md: 2, '2xl': 3 }}
									spacing={5}
									w="full"
								>
									{marketingData.map((card, index) => (
										<ChannelCard key={index} card={card} />
									))}
								</SimpleGrid>
							</Box>
						</Container>
						<Container minH="full">
							<FusionLoading isLoading={!areCampaignsLoaded} />
							<Box
								h={bottomCardsHeight}
								display={areCampaignsLoaded ? 'block' : 'none'}
								maxWidth="-webkit-fill-available"
							>
								<Campaigns
									simplified
									withContainer={false}
									noCampaignMessage={NothingToSee}
									onCampaignsLoaded={(campaigns) => {
										setAreCampaignsLoaded(true);
										!isEmpty(campaigns) && setHasCampaigns(true);
									}}
								/>
							</Box>
						</Container>
					</Flex>
					<Sidebar onClose={onGettingStartedClose} />
				</Flex>
			</Flex>
		</>
	);
};

export default Home;
