import { Flex } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { StringInputHook } from 'src/components/common/form';
import { ICreative, IDesignDirection, TextLayer } from 'src/lib/schemas';

interface DDTextFieldProps {
	layer: TextLayer;
	isLocked?: boolean;
	isChangingLayer: boolean;
	creativeData?: ICreative | IDesignDirection;
	fieldChanges?: () => void;
	setFieldValue: (field: string, value: string) => void;
}
const DDTextField: FC<DDTextFieldProps> = ({
	creativeData,
	layer,
	fieldChanges,
	isChangingLayer,
	setFieldValue,
}) => {
	const { name, displayName } = layer;
	const { field } = useController({ name });
	const { value: updatedValue } = field;

	useEffect(() => {
		if (updatedValue !== undefined) fieldChanges && fieldChanges();
	}, [updatedValue]);

	return (
		<Flex direction="column" gap={3}>
			<Flex
				gap={4}
				justify="space-between"
				alignItems="center"
				wrap="wrap-reverse"
			>
				<Flex fontSize="14px">{displayName}</Flex>
			</Flex>

			<Flex position="relative">
				<StringInputHook
					withAssistant
					displayName={displayName}
					creativeData={creativeData}
					setFieldValue={setFieldValue}
					name={name}
					inputProps={{
						isDisabled: isChangingLayer,
					}}
				/>
			</Flex>
		</Flex>
	);
};

export default DDTextField;
