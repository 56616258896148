import React, { useMemo, useRef, useState } from 'react';
import {
	Box,
	Text,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Spacer,
} from '@chakra-ui/react';
import { IDesignDirection } from 'src/lib/schemas';
import DDImage from './DDImage';
import CardActions from '../creatives/CardActions';

interface PromoCardProps {
	designDirection: IDesignDirection;
	openEditModal: () => void;
	onRetry: (designDirection: IDesignDirection) => void;
	onSelect: (designDirection: IDesignDirection) => void;
	onRemove: (id: string) => void;
	isSelected: boolean;
	isAnySelected: boolean;
}

const DDCard: React.FC<PromoCardProps> = ({
	designDirection,
	openEditModal,
	onRetry,
	onSelect,
	onRemove,
	isSelected,
	isAnySelected,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const cancelRef = useRef(null);
	const { status } = designDirection;
	const isLoading = useMemo(() => status !== 'GENERATED', [status]);
	const hasError = useMemo(
		() => status === 'ERROR' || status === 'failed',
		[status],
	);
	const disabledActions = isLoading || hasError;
	const onClose = () => setIsOpen(false);
	const [isHovering, setIsHovering] = useState(false);

	const handleRemoveClick = () => {
		setIsOpen(true);
	};

	const handleConfirmRemove = () => {
		onRemove(designDirection.id);
		setIsOpen(false);
	};

	const outerBoxStyles = {
		opacity: 1,
		cursor: 'pointer',
	};

	return (
		<Box
			w="240px"
			overflow="hidden"
			{...outerBoxStyles}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<DDImage
				designDirection={designDirection}
				onRetry={onRetry}
				onSelect={onSelect}
				isSelected={isSelected}
			/>
			<Spacer h={3} />
			<CardActions
				title={`Option ${designDirection.variant}`}
				onEdit={openEditModal}
				onRemove={handleRemoveClick}
				isSelected={isSelected}
				disabledActions={disabledActions}
			/>

			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isCentered
			>
				<AlertDialogOverlay zIndex={1900}>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Option {designDirection.variant}
						</AlertDialogHeader>

						<AlertDialogBody>
							<Text>
								Are you sure you want to delete this design direction?
							</Text>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme="blue" onClick={handleConfirmRemove} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Box>
	);
};

export default DDCard;
