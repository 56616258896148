import React, { useEffect, useState, useContext } from 'react';
import { genrateCampaignCreatives, getCampaign } from 'src/services/campaign';
import { toastError, toastSuccess } from 'src/services/toast';
import { CampaignContext } from 'src/contexts';

import {
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	Text,
	Spacer,
	HStack,
} from '@chakra-ui/react';
import EditDDForm from './EditDDForm';
import { IDesignDirection } from 'src/lib/schemas';
import { delay } from 'src/lib/utils';
import CustomModal from 'src/components/common/CustomModal';

interface EditDDFormModalProps {
	isOpen: boolean;
	onClose: () => void;
	handleRefreshDesignDirections: () => void;
	initialValues: IDesignDirection;
	designDirections: IDesignDirection[];
	setLoadingCreatives: (val: boolean) => void;
}

const EditDDModal: React.FC<EditDDFormModalProps> = ({
	isOpen,
	onClose,
	handleRefreshDesignDirections,
	initialValues,
	designDirections,
	setLoadingCreatives,
}) => {
	const [isSaving, setIsSaving] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [designerMode, setDesignerMode] = useState(false);
	const isLoading = isSaving || isUploading;
	const { id: campaignId, setCampaign } = useContext(CampaignContext);

	const handleSave = async (regenerateAll: boolean) => {
		onClose();
		await delay(1000);
		handleRefreshDesignDirections();

		if (regenerateAll) {
			if (!campaignId || !initialValues.id) return;
			try {
				setLoadingCreatives(true);
				await genrateCampaignCreatives(campaignId, initialValues.id);
				const data = await getCampaign(campaignId);
				setCampaign(data);
				toastSuccess('Creatives have been regenerated successfully');
			} catch (error) {
				toastError('Failed to regenerate creatives');
			}
		}
	};

	const modalHeader = (
		<HStack pr={10}>
			<Text size="md">
				<Text as="span" size="md" fontWeight="bold">
					Editing:{' '}
				</Text>
				Option {initialValues?.variant}
			</Text>
			<Spacer />
			<Button
				size="sm"
				variant="blankOutline"
				onClick={() => setDesignerMode(true)}
			>
				Switch to designer mode
			</Button>
		</HStack>
	);

	const modalFooter = (
		<>
			<Button onClick={onClose} mr={3}>
				Close
			</Button>
			<Button
				isLoading={isLoading}
				onClick={() => setIsSaving(true)}
				variant="orangeSolid"
				_hover={isLoading ? {} : { bg: 'orange.base' }}
			>
				Save changes
			</Button>
		</>
	);

	return (
		<CustomModal
			trapFocus={false}
			isOpen={isOpen}
			onClose={onClose}
			size="3xl"
			header={modalHeader}
			footer={modalFooter}
		>
			<EditDDForm
				initialValues={initialValues}
				isSaving={isSaving}
				setIsSaving={setIsSaving}
				setIsUploading={setIsUploading}
				onSave={handleSave}
				designerMode={designerMode}
				setDesignerMode={setDesignerMode}
				designDirections={designDirections}
				onRefetchDD={handleRefreshDesignDirections}
				closeEditModal={onClose}
			/>
		</CustomModal>
	);
};

export default EditDDModal;
