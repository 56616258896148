import { FC, useEffect } from 'react';
import { Button, Link } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { getFacebookOauthUrl } from 'src/lib/integrations';

interface FacebookAuthButtonProps {
	account: string;
}

const FacebookAuthButton: FC<FacebookAuthButtonProps> = ({ account }) => {
	const { pathname } = useLocation();

	return (
		<Link
			href={getFacebookOauthUrl({ account, redirectTo: `${pathname}?fromFB` })}
		>
			<Button colorScheme="secondary" size="sm">
				Connect
			</Button>
		</Link>
	);
};

export default FacebookAuthButton;
