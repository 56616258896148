import {
	ICampaign,
	ICampaigns,
	ICampaignsCalendar,
	ICreativeRegeneratePayload,
} from 'src/lib/schemas';
import http from './http';

const apiVersion = 'v2';
const campaignUrl = `/${apiVersion}/campaign`;

export const createOrUpdateCampaign = async (
	payload: Partial<Omit<ICampaign, 'id'>>,
	id: string,
): Promise<ICampaign> => {
	if (id && id !== 'new') {
		const { data } = await http.patch<ICampaign>(
			`${campaignUrl}/${id}`,
			payload,
		);
		return data;
	}
	const { data } = await http.post<ICampaign>(campaignUrl, payload);
	return data;
};

export const launchCampaign = async (id: string) => {
	await http.post(`${campaignUrl}/launch/${id}`);
};

export const getCampaigns = async (query: string) => {
	const { data } = await http.get<ICampaigns>(`${campaignUrl}?${query}`);
	return data;
};

export const getCampaign = async (id: string) => {
	const { data } = await http.get<ICampaign>(`${campaignUrl}/${id}`);
	return data;
};

export const executeCampaignCreatives = async (
	campaignId: string,
	backgroundPrompt?: string,
) => {
	const payload: { backgroundPrompt?: string } = {};
	if (backgroundPrompt) {
		payload.backgroundPrompt = backgroundPrompt;
	}
	await http.post(`${campaignUrl}/execute/${campaignId}`, payload);
};

export const genrateCampaignCreatives = async (
	campaignId: string,
	designDirectionId?: string,
	onlyUnprocessed: boolean = false,
) => {
	const id = designDirectionId ? `/${designDirectionId}` : '';
	await http.post(
		`${campaignUrl}/${campaignId}/generate-creatives${id}?onlyUnprocessed=${onlyUnprocessed}`,
	);
};

export const genrateCampaignDesignDirections = async (campaignId: string) => {
	await http.post(`${campaignUrl}/${campaignId}/design-direction`);
};

export const editCampaignDesignDirections = async (
	campaignId: string,
	designId: string,
	payload: any,
) => {
	await http.patch(
		`${campaignUrl}/${campaignId}/design-direction/${designId}`,
		payload,
	);
};

export const genrateNewDesignDirection = async (campaignId: string) => {
	await http.post(`${campaignUrl}/${campaignId}/add-design-direction/1`);
};
export const removeDesignDirection = async (
	campaignId: string,
	designDirectionId: string,
) => {
	await http.delete(
		`${campaignUrl}/${campaignId}/design-direction/${designDirectionId}`,
	);
};

export const regenerateCampaignCreative = async (
	campaignId: string,
	payload: ICreativeRegeneratePayload,
) => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/regenerate/${campaignId}/${payload.creativeId}`,
		payload,
	);
	return data;
};

export const regenerateCreativesVariant = async (
	id: string,
	variant: 'A' | 'B',
	payload: Partial<ICreativeRegeneratePayload>,
) => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/regenerate/${id}/${variant}`,
		payload,
	);
	return data;
};

export const getCampaignCalendar = async (query?: Record<string, string>) => {
	const { data } = await http.get<ICampaignsCalendar>(
		`${campaignUrl}/calendar?${new URLSearchParams(query).toString()}`,
	);
	return data;
};

export const updateCampaignCreative = async (
	id: string,
	creativeId: string,
	payload: any,
) => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/${id}/update-creative/${creativeId}`,
		payload,
	);

	return data;
};
