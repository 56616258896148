import {
	Box,
	Image,
	Text,
	Button,
	Flex,
	HStack,
	Avatar,
} from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import { useContext } from 'react';
import UserContext from 'src/contexts/UserContext';
import { CampaignContext } from 'src/contexts';

import CreativeCard from './CreativeCard';
import MiniGlobeIcon from 'src/assets/icons/MiniGlobe';
import { IChannelCreativeAttributes } from 'src/lib/schemas';
import { formatCallToAction } from './utils/formatedCTA';
import PhotoEditorPreview from '../design-directions/PhotoEditorPreview';
import DDPhotoEditor, {
	DDPhotoEditorProps,
} from '../design-directions/DDPhotoEditor';

interface SquareCreativeProps extends Partial<DDPhotoEditorProps> {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
	creativesConfig?: IChannelCreativeAttributes[];
}

const SquareCreative = ({
	creative,
	withActions = true,
	size = 'md',
	creativesConfig,
	...photoEditorProps
}: SquareCreativeProps) => {
	const { user, account } = useContext(UserContext);
	const { campaign } = useContext(CampaignContext);
	const destination =
		campaign?.destination ?? 'https://www.website.com/feed/html';
	const image = (creative as any).attributes.image.flatFile;
	const { primaryText, headline, callToAction } = (creative as any).attributes;
	const cleanedDomain = destination
		.replace(/(^\w+:|^)\/\//, '')
		.replace(/^www\./, '')
		.split('/')[0];

	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			size={size}
			title="Feed"
			creativesConfig={creativesConfig}
		>
			<Box p={4}>
				<Flex alignItems="center" mb={4} gap={2}>
					<Avatar
						src={account?.logo}
						sx={{
							img: {
								objectFit: 'contain',
							},
						}}
					/>
					<Box>
						<Text fontWeight="bold">{user?.name}</Text>
						<HStack spacing={1}>
							<Text color="black" fontSize="sm">
								Sponsored ·
							</Text>
							<MiniGlobeIcon />
						</HStack>
					</Box>
				</Flex>
				<Text mb={2} noOfLines={2} overflow="hidden">
					{primaryText}
				</Text>
				<Text color="blue.500" isTruncated>
					{destination}
				</Text>
			</Box>

			<Image src={image} alt="Preview" fallbackSrc="/fusion-loader.gif" />

			{/* {photoEditorProps.designDirection &&
				photoEditorProps.isChangingLayer !== undefined &&
				photoEditorProps.onRefetchCreatives && (
					<DDPhotoEditor
						key={photoEditorProps.layeredFile}
						layeredFile={photoEditorProps.layeredFile}
						textLayerContent={photoEditorProps.textLayerContent}
						font={photoEditorProps.font}
						designDirection={photoEditorProps.designDirection}
						isChangingLayer={photoEditorProps.isChangingLayer}
						onRefetchCreatives={photoEditorProps.onRefetchCreatives}
					/>
				)} */}
			<HStack
				p={4}
				bg="gray.50"
				borderTop="1px solid"
				borderColor="gray.200"
				borderBottomRadius="lg"
			>
				<Box maxW="80%">
					<Text fontSize="sm" color="gray.500" isTruncated>
						{cleanedDomain}
					</Text>
					<Text fontWeight="bold" noOfLines={1}>
						{headline}
					</Text>
					<Text fontSize="sm" color="gray.500" noOfLines={2}>
						{primaryText}
					</Text>
				</Box>
				{callToAction && (
					<Button
						size="sm"
						cursor="default"
						bg="gray.100"
						px={8}
						_hover={{ bg: 'gray.100' }}
						fontWeight={500}
					>
						{formatCallToAction(callToAction)}
					</Button>
				)}
			</HStack>
		</CreativeCard>
	);
};

export default SquareCreative;
