import { FC, useState, useMemo } from 'react';
import { Flex, Image, Text, Box, VStack } from '@chakra-ui/react';
import { IDesignDirection } from 'src/lib/schemas';
import { IoMdRefreshCircle } from 'react-icons/io';
import { LuEye } from 'react-icons/lu';
import ContentPendingProgress from 'src/components/common/ContentPendingProgress';
import LoadingList from './LoadingList';

interface CarouselImageProps {
	designDirection: IDesignDirection;
	onRetry: (designDirection: IDesignDirection) => void;
	onSelect: (designDirection: IDesignDirection) => void;
	isSelected: boolean;
}

const DDImage: FC<CarouselImageProps> = ({
	designDirection,
	onRetry,
	onSelect,
	isSelected,
}) => {
	const { attributes, status } = designDirection;
	const [isHovering, setIsHovering] = useState(false);
	const link = (attributes as any)?.image?.flatFile;
	const isPendingGeneration = useMemo(() => status !== 'GENERATED', [status]);
	const hasError = useMemo(
		() => status === 'ERROR' || status === 'failed',
		[status],
	);

	const imageStyles = {
		filter: isSelected ? 'none' : 'brightness(100%)',
	};

	return (
		<Flex direction="column" alignItems="center" gap={6} flexShrink={0}>
			<Flex
				justify="center"
				alignItems="center"
				minW="240px"
				minH="240px"
				onMouseEnter={() =>
					!hasError && !isPendingGeneration && setIsHovering(true)
				}
				onMouseLeave={() => setIsHovering(false)}
				onClick={() => !isPendingGeneration && onSelect(designDirection)}
				{...imageStyles}
				cursor={isPendingGeneration ? 'default' : 'pointer'}
			>
				<Flex
					zIndex="3"
					position={isHovering ? 'relative' : 'static'}
					w="full"
					h="full"
				>
					<Flex
						w="full"
						justify="center"
						alignItems="center"
						position="relative"
					>
						{!hasError && (
							<>
								<Flex
									position="absolute"
									borderRadius="2xl"
									w="240px"
									h="240px"
								>
									<Box
										borderRadius="2xl"
										display={isPendingGeneration ? 'block' : 'none'}
										position="relative"
										zIndex="-1"
										backgroundImage="url('/images/background_preview_DD.webp')"
										backgroundSize="cover"
										backgroundPosition="center"
									/>
									{isPendingGeneration && (
										<LoadingList
											isPendingGeneration={isPendingGeneration}
											hasError={hasError}
										/>
									)}
								</Flex>
							</>
						)}
						{hasError && (
							<Flex
								p={5}
								alignItems="center"
								direction="column"
								textAlign="center"
								gap={2}
								color="gray.500"
								bg="gray.100"
								borderRadius="2xl"
								w="240px"
								h="240px"
								justifyContent="center"
							>
								<Text>
									There was an issue generating the image. Please give it a
									retry.
								</Text>
								<IoMdRefreshCircle
									cursor="pointer"
									size={50}
									onClick={() => onRetry(designDirection)}
								/>
							</Flex>
						)}
						<Image
							display={hasError ? 'none' : 'block'}
							src={link}
							fallbackSrc="/fusion-loader.gif"
							w="auto"
							h="full"
							borderRadius="2xl"
							{...imageStyles}
						/>
					</Flex>
					{!isSelected && isHovering && (
						<Flex
							cursor="pointer"
							position="absolute"
							zIndex={1}
							justify="center"
							alignItems="center"
							w="full"
							h="full"
							transition="ease-in"
							background="blackAlpha.700"
							borderRadius="2xl"
						>
							<VStack spacing={2}>
								<LuEye color="white" size={35} />
								<Text color="white" size="md">
									Preview marketing content
								</Text>
							</VStack>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default DDImage;
