import { useState, useEffect, FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LoadingItem from './LoadingItem';

interface ILoadingList {
	isPendingGeneration: boolean;
	hasError: boolean;
	size?: any;
}

const LoadingList: FC<ILoadingList> = ({
	isPendingGeneration,
	hasError,
	size = '438px',
}) => {
	const [currentStep, setCurrentStep] = useState(0);
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		let timer1: NodeJS.Timeout | null = null;
		let timer2: NodeJS.Timeout | null = null;
		let timer3: NodeJS.Timeout | null = null;

		if (isPendingGeneration) {
			setCurrentStep(0);
			timer1 = setTimeout(() => setCurrentStep(1), 6000);
			timer2 = setTimeout(() => setCurrentStep(2), 18000);
			timer3 = setTimeout(() => setCurrentStep(3), 36000);
		}

		return () => {
			if (timer1) clearTimeout(timer1);
			if (timer2) clearTimeout(timer2);
			if (timer3) clearTimeout(timer3);
		};
	}, [isPendingGeneration]);

	useEffect(() => {
		if (isPendingGeneration) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}

		const visibilityTimer = setTimeout(() => {
			setIsVisible(false);
		}, 60000);
		return () => clearTimeout(visibilityTimer);
	}, [isPendingGeneration]);

	return (
		<Box
			position="relative"
			width={size}
			height={size}
			zIndex="1"
			display={isVisible && !hasError ? 'flex' : 'none'}
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			textAlign="center"
		>
			<Box
				zIndex="-2"
				position="absolute"
				width="100%"
				height="100%"
				borderRadius="15px"
				backgroundImage="url('/images/background_preview_DD.webp')"
				backgroundSize="cover"
				backgroundPosition="center"
			/>
			<Box
				zIndex="-1"
				position="absolute"
				width="100%"
				height="100%"
				borderRadius="15px"
				bgColor="black"
				opacity="0.4"
				display="block"
			/>
			<Flex
				direction="column"
				gap="3"
				justifyContent="center"
				alignItems="left"
				h="100%"
			>
				<LoadingItem
					text="Building the best layout"
					isLoading={currentStep < 1}
					currentStep={currentStep}
					step={1}
				/>
				<LoadingItem
					text="Generating ad"
					isLoading={currentStep < 2}
					currentStep={currentStep}
					step={2}
				/>
				<LoadingItem
					text="Splitting in multiple layers"
					isLoading={currentStep < 3}
					currentStep={currentStep}
					step={3}
				/>
			</Flex>
		</Box>
	);
};

export default LoadingList;
