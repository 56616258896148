import { Box, Flex, Heading, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { IUpdatePayload } from 'src/contexts/templates/TemplatesContext';
import { ImageListItem } from './commponents/ImageListItem';

interface IImageListTemplatesProps {
	payloads: IUpdatePayload[];
	payloadLength: number;
}
export const ImageListTemplates = (props: IImageListTemplatesProps) => {
	const { payloads, payloadLength } = props;
	const hImages = payloads.filter((payload) => payload.imageType === 'H');
	const vImages = payloads.filter((payload) => payload.imageType === 'V');

	const hImagesSorted = hImages.sort((a, b) =>
		a.variationId.localeCompare(b.variationId),
	);

	const vImagesSorted = vImages.sort((a, b) =>
		a.variationId.localeCompare(b.variationId),
	);

	return (
		<Flex mt="40px" justifyContent="center" gap="50px">
			<Box>
				<Heading fontWeight="medium">Horizontal Images</Heading>
				{hImagesSorted.map((payload) => (
					<ImageListItem key={payload.variationId} payload={payload} />
				))}
			</Box>

			<Box>
				<Heading fontWeight="medium">Vertical Images</Heading>
				{vImagesSorted.map((payload) => (
					<ImageListItem key={payload.variationId} payload={payload} />
				))}
			</Box>
		</Flex>
	);
};
