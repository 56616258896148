import { Box, Flex, FormLabel, Image, Link, Text } from '@chakra-ui/react';
import { IUpdatePayload } from 'src/contexts/templates/TemplatesContext';

interface IImageItemProps {
	payload: IUpdatePayload;
}

export const ImageListItem = ({ payload }: IImageItemProps) => {
	const width = Number(payload.attributes.image.dimensions.width);
	const height = Number(payload.attributes.image.dimensions.height);
	const aspectRatio = width / height;
	const fixedWidth = '400px';

	const variationIdMap: { [key: string]: string } = {
		facebook_story: 'FB/IG story',
		facebook_feed: 'FB feed',
		design: 'Design direction',
		instagram_feed: 'IG feed',
	};

	const variationText =
		variationIdMap[payload.variationId] || payload.variationId;

	return (
		<Flex
			key={payload.variationId}
			display="flex-column"
			justifyContent="center"
		>
			<Text mb="2px" mt="20px" fontWeight="medium">
				{variationText}
			</Text>
			<Flex mb="5px" fontWeight="medium" alignItems="center">
				<Text whiteSpace="nowrap">Product image:</Text>
				<Text
					as="span"
					color="blue.500"
					ml="2"
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
					maxWidth="250px"
				>
					<a href={payload.attributes.image.flatFile}>
						{payload.attributes.image.flatFile}
					</a>
				</Text>
			</Flex>
			{payload.logoUrl.length >= 1 ? (
				<Flex mb="5px" fontWeight="medium" alignItems="center">
					<Text whiteSpace="nowrap">Logo:</Text>
					<Text
						as="span"
						color="blue.500"
						ml="2"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						maxWidth="250px"
					>
						<a href={payload.logoUrl}>{payload.logoUrl}</a>
					</Text>
				</Flex>
			) : (
				<Box></Box>
			)}

			<Box border="1px solid #ddd">
				<Image
					src={payload.attributes.image.flatFile}
					width="400px"
					height={`calc(${fixedWidth} / ${aspectRatio})`}
					objectFit="contain"
					alt={`Image for variation ${payload.variationId}`}
				/>
			</Box>
		</Flex>
	);
};
