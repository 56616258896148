import { Box, Button, Text, VStack } from '@chakra-ui/react';

interface GenerateOrSelectCardProps {
	onGenerate: () => void;
}

const GenerateOrSelectCard = ({ onGenerate }: GenerateOrSelectCardProps) => {
	return (
		<Box
			w="240px"
			h="240px"
			p={8}
			borderRadius="2xl"
			textAlign="center"
			bg="white"
			borderWidth="1px"
			display="flex"
			alignItems="center"
			justifyContent="center"
			px={5}
		>
			<VStack spacing={4} w="full"  >
				<Button onClick={onGenerate} size="lg" variant="orangeSolid" w="full">
					Generate new design
				</Button>
				{/* <Text size="md">Or</Text>
				<Button size="lg" variant="orangeOutline" w="full">
					Select template
				</Button> */}
			</VStack>
		</Box>
	);
};

export default GenerateOrSelectCard;
