import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, Flex, VStack, Box } from '@chakra-ui/react';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas';
import DDFields from './DDFields';
import _ from 'lodash';
import {
	IPayload,
	IPerview,
} from 'src/components/campaign/DesignOutput/parts/DesignDirections/parts/utils';
import { updateCampaignCreative } from 'src/services/campaign';
import http from 'src/services/http';
import { toastError, customToast, toastSuccess } from 'src/services/toast';
import { CampaignContext } from 'src/contexts';
import DDPreview from './DDPreview';

interface EditDDModalProps {
	initialValues: IDesignDirection;
	designDirections: IDesignDirection[];
	isSaving: boolean;
	setIsSaving: (val: boolean) => void;
	designerMode: boolean;
	setDesignerMode: (val: boolean) => void;
	setIsUploading: (val: boolean) => void;
	onSave: (regenerateAll: boolean) => void;
	onRefetchDD: () => void;
	closeEditModal: () => void;
}

const EditDDForm: React.FC<EditDDModalProps> = ({
	initialValues,
	isSaving,
	designerMode,
	setIsSaving,
	setDesignerMode,
	setIsUploading,
	onSave,
	designDirections,
	onRefetchDD,
	closeEditModal,
}) => {
	const { id: campaignId, campaign } = useContext(CampaignContext);
	const [textLayersContent, setTextLayersContent] = useState<any>([]);
	const [isPreviewLoading, setIsPreviewLoading] = useState(false);
	const [isChangingImageLayer, setIsChangingImageLayer] = useState(false);
	const [isGeneratingDD, setIsGeneratingDD] = useState(false);
	const [editMode, setEditMode] = useState(true);
	const [regenerateAll, setRegenerateAll] = useState(true);
	const { flatFile, layeredFile, layers } = (initialValues as any).attributes
		.image;
	const [previewPSDMeta, setPreviewPSDMeta] = useState<IPerview>({
		flatFile,
		layeredFile,
		layers,
	});
	const [currentDesignDirection, setCurrentDesignDirection] =
		useState<IDesignDirection>(initialValues);

	useEffect(() => {
		if (isSaving) {
			try {
				handleEditDD(currentDesignDirection);
			} catch (error) {
				toastError(error);
				setIsSaving(false);
			}
		}
	}, [isSaving]);

	useEffect(() => {
		setIsUploading(isChangingImageLayer);
	}, [isChangingImageLayer]);

	const mediaContent =
		currentDesignDirection?.attributes as unknown as IChannelMediaAttributes;

	useEffect(() => {
		const image = mediaContent?.image || {};
		if (currentDesignDirection && image && image.layeredFile) {
			setPreviewPSDMeta((prevState) => ({
				...prevState,
				flatFile: image.flatFile,
				layeredFile: image.layeredFile,
				layers: image.layers,
			}));
		}
	}, [mediaContent?.image?.layeredFile]);

	useEffect(() => {
		if (currentDesignDirection !== null) {
			const updatedDD = designDirections.find(
				(dd) => dd.id === currentDesignDirection.id,
			);
			updatedDD && setCurrentDesignDirection(updatedDD);
		}
	}, [designDirections, currentDesignDirection]);

	const headlineTextLayers = mediaContent?.image.layers?.filter(
		(layer) => layer.type === 'text' && layer.primary,
	) as TextLayer[];

	const handleChangeTextLayerContent = useCallback(() => {
		document.getElementById('change-text-layer-button-preview')?.click();
	}, []);

	const handleLayersTextChange = useCallback(
		(content: any, layers: any) => {
			const cleanContent = _.cloneDeep(content);
			const hasNestedObject = (obj: any): boolean => {
				return Object.values(obj).some((val) => typeof val === 'object');
			};

			hasNestedObject(cleanContent) &&
				Object.keys(cleanContent).forEach((key) => {
					if (key.includes('.')) delete cleanContent[key];
				});
			const layersCopy = [...(mediaContent?.image?.layers ?? [])];
			const newLayers: any = [];
			layers.forEach((layer: any) => {
				let currentLayer: any = layer;
				const parents = [];
				while (currentLayer.parentName) {
					const parentLayer = layersCopy.find(
						(layer) => layer.name === currentLayer.parentName,
					);
					if (parentLayer) parents.unshift(currentLayer.parentName);
					currentLayer = parentLayer;
				}

				const newLayer = {
					name: layer.name,
					content: _.get(cleanContent, layer.name),
					parents,
				};
				newLayers.push(newLayer);
			});
			setTextLayersContent(newLayers);
			handleChangeTextLayerContent();
		},
		[mediaContent],
	);

	const updateDD = async (
		payload: IPerview,
		designDirection: IDesignDirection,
	) => {
		const attributes =
			designDirection?.attributes as unknown as IChannelMediaAttributes;
		if (!attributes || !campaignId) return;

		const updatePayload = {
			attributes: {
				...attributes,
				textLayersContent,
				image: {
					...attributes.image,
					layeredFile: payload.layeredFile,
					flatFile: payload.flatFile,
					layers: payload.layers,
				},
				headline: textLayersContent.find(
					(layer: any) => layer.name === '$Headline',
				)?.content,
			},
		};
		await updateCampaignCreative(campaignId, designDirection.id, updatePayload);
		await onRefetchDD();
	};

	const resetLoadingStates = (payload: IPayload) => {
		setIsPreviewLoading(false);
		payload.actions.action === 'newDD' && setIsGeneratingDD(false);
		payload.actions.action === 'changeImage' && setIsChangingImageLayer(false);
	};

	const changeTextLayer = async (payload: IPayload, dd?: IDesignDirection) => {
		const { layeredFile } = payload.psdPerview;
		const values = payload.text.map((layer: any) => ({
			value: layer.content,
			layer: layer.name,
			property: 'text',
		}));

		const changeLayerPayload = {
			inputs: {
				layeredFile,
				values,
			},
		};

		try {
			const { data } = await http.post(
				'/v2/apps/fusion_ai.edit_psd/execute/edit_psd',
				changeLayerPayload,
			);
			if (data) await processCallback(data.callback, payload, dd);
		} catch (error) {
			toastError(error);
			resetLoadingStates(payload);
			setIsSaving(false);
		}
	};

	const processCallback = async (
		callbackUrl: string,
		payload: IPayload,
		dd?: IDesignDirection,
	) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl, payload, dd), 500);
				// setIsSaving(true);
			} else if (status === 'error' || status === 'failed') {
				customToast('Error processing image', 'error');
				resetLoadingStates(payload);
				setIsSaving(false);
			} else if (status === 'successful') {
				const updatedLayers = headlineTextLayers.map((layer: any) => {
					return {
						...layer,
						content: payload.text.find((l: any) => l.name === layer.name)
							.content,
					};
				});
				const filterdLayers = payload.psdPerview.layers.filter(
					(layer: any) =>
						!headlineTextLayers.some((l: any) => l.name === layer.name),
				);
				const layers = [...filterdLayers, ...updatedLayers];

				setPreviewPSDMeta((prevState) => ({
					...prevState,
					flatFile: body.flatFile,
					layeredFile: body.layeredFile,
					layers: layers,
				}));
				if (isSaving) {
					await updateDD(
						{
							flatFile: body.flatFile,
							layeredFile: body.layeredFile,
							layers,
						},
						payload.designDirection,
					);
					onSave(regenerateAll);
					toastSuccess('Your changes have been saved');
					setIsSaving(false);
				}

				payload.actions.changeDD &&
					dd &&
					setCurrentDesignDirection((prev) => {
						if (prev?.id === dd.id) return prev;
						setEditMode(true);
						return dd;
					});

				resetLoadingStates(payload);
			}
		} catch (e) {
			console.log({ e });
			toastError(e);
			resetLoadingStates(payload);
			setIsSaving(false);
		}
	};

	const handleEditDD = (dd: IDesignDirection) => {
		setIsPreviewLoading(true);
		try {
			if (currentDesignDirection && editMode) {
				const payload: IPayload = {
					designDirection: currentDesignDirection,
					psdPerview: {
						flatFile: previewPSDMeta.flatFile,
						layeredFile: previewPSDMeta.layeredFile,
						layers: previewPSDMeta.layers,
					},
					text: textLayersContent,
					actions: {
						action: 'changeDD',
						generateCreatives: false,
						editMode: false,
						changeDD: true,
					},
				};
				changeTextLayer(payload, dd);
			} else {
				setCurrentDesignDirection((prev) => {
					if (prev?.id === dd.id) return prev;
					setEditMode(true);
					return dd;
				});
				setIsPreviewLoading(false);
				setIsSaving(false);
			}
		} catch (error) {
			console.log(error);
			setIsPreviewLoading(false);
			setIsSaving(false);
		}
	};

	const handleChangingPreviewLoading = (status: Record<string, boolean>) => {
		const { preview, newDD, changeImage } = status;
		preview !== undefined && setIsPreviewLoading(preview);
		newDD !== undefined && setIsGeneratingDD(newDD);
		changeImage !== undefined && setIsChangingImageLayer(changeImage);
	};

	const handleChangeMediaImage = (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => {
		if (isChangingImageLayer) {
			return;
		}

		console.log('handleChangeMediaImage called');

		setPreviewPSDMeta((prevState) => ({
			...prevState,
			flatFile: flatFile,
			layeredFile: layeredFile,
			layers: layers,
		}));

		setIsChangingImageLayer(true);

		const payload: IPayload = {
			designDirection: currentDesignDirection,
			psdPerview: {
				flatFile: flatFile,
				layeredFile: layeredFile,
				layers: layers,
			},
			text: textLayersContent,
			actions: {
				action: 'changeImage',
				generateCreatives: false,
				editMode: false,
			},
		};

		changeTextLayer(payload);
	};

	return (
		<Flex w="full" direction="column" gap={5}>
			<Flex w="full" gap={5}>
				<VStack
					spacing={4}
					align="stretch"
					flex={1}
					overflowY="auto"
					maxH="70vh"
					pr={4}
					pl={2}
				>
					<DDFields
						designDirection={initialValues}
						onLayersTextChange={handleLayersTextChange}
						previewPSD={(initialValues?.attributes as any).image}
						isChangingLayer={isChangingImageLayer}
						onChangingLayer={setIsChangingImageLayer}
						onChangePreviewPSD={handleChangeMediaImage}
					/>
				</VStack>

				<VStack spacing={4} align="center" flex={1}>
					<DDPreview
						designDirection={initialValues}
						textLayerContent={textLayersContent}
						onRefetchDesignDirections={onRefetchDD}
						previewPSD={previewPSDMeta.layeredFile}
						isChangingLayer={isChangingImageLayer}
						setDesignerMode={setDesignerMode}
						designerMode={designerMode}
						closeEditModal={closeEditModal}
					/>  
				</VStack>
			</Flex>

			<Box
				w="full"
				border="1px solid"
				borderColor="#E5E7EB"
				borderRadius="8px"
				bg="transparent"
				p={4}
				mt={4}
			>
				<Checkbox
					isChecked={regenerateAll}
					onChange={(e) => setRegenerateAll(e.target.checked)}
					sx={{
						whiteSpace: 'nowrap',
						maxWidth: '100%',
						'& .chakra-checkbox__label': {
							fontSize: '14px',
						},
						'& .chakra-checkbox__control[data-checked]': {
							backgroundColor: '#F7480B',
							borderColor: '#F7480B',
						},
					}}
				>
					Also regenerate content for all the channels
				</Checkbox>
			</Box>
		</Flex>
	);
};

export default EditDDForm;
