import React from 'react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import MessageCreative from '../MessageCreative';
import TwitterCreative from '../TwitterCreative';
import SquareCreative from '../SquareCreative';
import StoryCreative from '../StoryCreative';
import GoogleCreative from '../GoogleCreative';
import EmailCreative from '../EmailCreative';
import { Flex } from '@chakra-ui/react';
import { IChannelCreativeAttributes } from 'src/lib/schemas';
import { DDPhotoEditorProps } from '../../design-directions/DDPhotoEditor';
import CustomCreative from '../CustomCreative';

const placementComponentMap: {
	[key: string]: React.ComponentType<{
		creative: ICreative;
		withActions?: boolean;
		size?: string;
		fieldIndex?: number;
		creativesConfig?: IChannelCreativeAttributes[];
	}>;
} = {
	smsDirectMarketing: MessageCreative,
	twitterSocialMedia: TwitterCreative,
	instagramFeedPaidAd: SquareCreative,
	instagramStoryPaidAd: StoryCreative,
	instagramFeedSocialMedia: SquareCreative,
	instagramStorySocialMedia: StoryCreative,
	facebookFeedSocialMedia: SquareCreative,
	facebookStorySocialMedia: StoryCreative,
	facebookFeedPaidAd: SquareCreative,
	facebookStoryPaidAd: StoryCreative,
	googlePMAX: GoogleCreative,
	emailDirectMarketing: EmailCreative,
	a6: CustomCreative, 
};

interface RenderCreativesByPlacementProps extends Partial<DDPhotoEditorProps> {
	creatives: ICreative[];
	withActions?: boolean;
	size?: string;
	fieldIndex?: number;
	creativesConfig?: IChannelCreativeAttributes[];
}
export const renderCreativesByPlacement = ({
	creatives,
	withActions = true,
	size = 'sm',
	fieldIndex = 0,
	creativesConfig,
	...photoEditorProps
}: RenderCreativesByPlacementProps) => {
	const renderedComponents: React.ReactNode[] = [];
	const seenComponentTypes = new Set<string>();

	creatives.forEach((creative) => {
		creative.placements?.forEach((placement) => {
			const Component = placementComponentMap[placement];
			const componentTypeName = Component?.name;

			if (Component && !seenComponentTypes.has(componentTypeName)) {
				renderedComponents.push(
					<Component
						key={placement + creative.id}
						creative={creative}
						withActions={withActions}
						size={size}
						fieldIndex={fieldIndex}
						creativesConfig={creativesConfig}
						{...photoEditorProps}
					/>,
				);
				seenComponentTypes.add(componentTypeName);
			}
		});
	});

	return (
		<Flex wrap="wrap" gap={4}>
			{renderedComponents}
		</Flex>
	);
};
