import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { BiUpload } from 'react-icons/bi';
import { uploadFileToStorage } from 'src/services/storage';
import { customToast, toastError } from 'src/services/toast';
import { FileRejection, useDropzone } from 'react-dropzone';

interface FileInputProps<T extends FieldValues> {
	name: FieldPath<T>;
	uploadButtonText?: string;
	accept?: string;
	uploadPath?: string;
	onUploadingStatusChange?: (isUploading: boolean) => void;
	onUrlChange?: (url: string) => void;
	onDrop?: (
		acceptedFiles: File[] | string,
		fileRejections: FileRejection[],
	) => void;
	onLoading?: (loading: boolean) => void;
}

const FileInput: FC<FileInputProps<FieldValues>> = ({
	name,
	uploadButtonText,
	accept = 'image/*',
	uploadPath = '',
	onUploadingStatusChange,
	onUrlChange,
	onDrop,
	onLoading,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		onLoading && onLoading(isLoading);
	}, [isLoading, onLoading]);

	const getAcceptObject = (accept: string) => {
		return accept.split(',').reduce(
			(acc, type) => {
				type = type.trim();
				if (type.includes('/')) {
					acc[type] = [];
				} else if (type.startsWith('.')) {
					acc['application/octet-stream'] = acc['application/octet-stream']
						? [...acc['application/octet-stream'], type]
						: [type];
				}
				return acc;
			},
			{} as Record<string, string[]>,
		);
	};

	const handleFileChange = async (file: File) => {
		if (!file) return;
		if (!isValidFile(file)) {
			customToast(`Only ${accept} files are allowed.`, 'error');
			return;
		}

		onUploadingStatusChange && onUploadingStatusChange(true);
		setIsLoading(true);
		try {
			const publicUrl = await uploadFileToStorage(file, uploadPath);
			onUrlChange && onUrlChange(publicUrl);
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
		onUploadingStatusChange && onUploadingStatusChange(false);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
			if (fileRejections.length > 0) {
				fileRejections.forEach((fileRejection) => {
					customToast(
						`File type not accepted: ${fileRejection.file.name}`,
						'error',
					);
				});
			} else {
				handleFileChange(acceptedFiles[0]);
			}
		},
		accept: getAcceptObject(accept),
		maxFiles: 1,
	});

	const isValidFile = (file: File): boolean => {
		const allowedExtensions = accept
			.split(',')
			.map((ext) => ext.trim().toLowerCase());
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		return fileExtension
			? allowedExtensions.includes(`.${fileExtension}`)
			: false;
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			handleFileChange(file);
		}
	};

	return (
		<Box {...getRootProps()} w="150px">
			<input
				{...getInputProps()}
				type="file"
				accept={accept}
				onChange={handleInputChange}
				style={{ display: 'none' }}
				id={name}
			/>
			<Button
				htmlFor={name}
				as="label"
				w="150px"
				leftIcon={<BiUpload size={18} />}
				variant="orangeOutline"
				isLoading={isLoading}
				loadingText="Uploading..."
				cursor="pointer"
				_hover={{ bg: 'inherit' }}
				textAlign="center"
				bg={isDragActive ? 'blue.100' : 'white'}
			>
				{uploadButtonText ?? `Upload ${name}`}
			</Button>
		</Box>
	);
};

export default FileInput;
